import { Divider, ListItemButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Search } from '../Search';
import { SmartRouter } from '../../services/Router';
import { adminAxios } from '../../services/http/adminAxios';


function ToolboxHead(props) {
    const router = new SmartRouter(); 
    const [isSearch, setIsSearch] = useState(false)

    const onChoose = (option) => {
        const instanceName = option.instanceName
        const id = option.id
        
        router.to(`/admin/${instanceName}/${id}`)
    }

    const ToolboxHeadContainer = styled.div`
        height: 64px;
        display: grid;
        grid-template-columns: auto 64px;
    `

    const SearchStyled = styled.div`
        display: grid;
        align-content: center;
    `
    
    const ToolboxHeadTitleStyled = styled(Typography)`
        font-size: 18px !important;
        font-weight: 700 !important;
        line-height: 22px !important;
        letter-spacing: 0px !important;
        margin-left: 7px !important;
    `
    
    const Content = () => 
        <ToolboxHeadContainer>
            <ListItemButton onClick={()=>router.to('/admin')}>
                <ToolboxHeadTitleStyled>
                    Content    
                </ToolboxHeadTitleStyled> 
            </ListItemButton>
            <SearchStyled>
            <Search 
                isSearch={isSearch} 
                setIsSearch={setIsSearch}
                onChoose={onChoose}
            />
            </SearchStyled>
        </ToolboxHeadContainer>

    return (
        <div>
            {!isSearch&&(<Content />)}
            {isSearch&&(<Search isSearch={isSearch} setIsSearch={setIsSearch} onChoose={onChoose} />)}
            <Divider />
        </div>
    );
}

const thObs = observer(ToolboxHead);
export {thObs as ToolboxHead};