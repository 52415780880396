import { useNavigate } from "react-router-dom"
import { mainStore } from "../store/MainStore"
import { useState } from "react"

export class SmartRouter {
    router = useNavigate()

    to = (url) => {
        if (!mainStore.isSaved && mainStore.isSaved !== null) {
            mainStore.saveDialogResult = null
            mainStore.isSaveDialogOpen = true
            mainStore.routeUrlFromDialog = url
        }

        if (!mainStore.isSaveDialogOpen) this.router(url)
    }
}
