import { Button, Paper, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SmartRouter } from '../services/Router';
import ThemeChangeButton from '../components/ThemeChangeButton';


function StartPage(props) {
    const router = new SmartRouter()
    return (
            <Paper elevation={2} style={{display: 'grid', height: 'max-content', margin: '80px 0 100px 117px', width: 732, justifyItems: 'center'}}>
                <Markdown style={{margin: '40px 40px 0 40px'}}>
                    # Hi! You are on start page 
                </Markdown>
                <Typography color={'text.secondary'} fontSize={25} style={{margin: '40px 40px 0 40px'}}>Try to change theme<ThemeChangeButton /></Typography>
                <Typography color={'text.secondary'} fontSize={25} style={{margin: 40}}><ArrowBackIcon style={{marginBottom: -3}}/>To create new content, open one of the tabs on the left</Typography>
                <Button variant='contained' size='large' style={{margin: '0 0 60px', width: 'max-content'}} onClick={()=>{router.to('/admin/projects-owners/create')}} startIcon={<ArrowForwardIcon />}><Typography fontSize={20} >or get started with new project owner</Typography></Button>
            </Paper>
    );
}

export default StartPage;