import React from 'react';
import styled from "styled-components";
import {FormControlLabel, Switch} from "@mui/material";
import { mainStore } from '../store/MainStore';


function ThemeChangeButton() {
    const themeToggler = () => {
        mainStore.theme = mainStore.theme === 'light' ? 'dark' : 'light'
    }

    const switcher = <
        Switch
            defaultChecked={mainStore.theme === 'dark'}
            onChange={themeToggler}
        />

    const switcherWithLabel = <
        FormControlLabel
                control={switcher}
                label={mainStore.theme}
                labelPlacement={'start'}
        />

    const ThemeSwitcherBlock = styled.div`
          display: grid;
          justify-items: center;
        `

            return <ThemeSwitcherBlock>{switcherWithLabel}</ThemeSwitcherBlock>;
}

export default ThemeChangeButton;