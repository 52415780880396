import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { SmartRouter } from "../../services/Router"
import TokenService from "../../services/http/TokenService"
import { UniversalHttp } from "../../services/http/UniversalHttp"
import { mainStore } from "../../store/MainStore"
import userStore from "../../store/UserStore"
import { OnUserNotAuthentificatedPopup, SaveAlertDialog, DeleteAlertDialog, ServerErrorSnackbar, ValidationError, OnConflictPopup, OnLogoutDialog, OnNotFound, OnForbiddenPopup, OnPublishSuccessPopup } from "./Popups"

const AllPopUps = () => {
    const router = new SmartRouter()
  
    useEffect(()=>{
      if (!TokenService.getRefreshTokenValidity() && !window.location.pathname.endsWith('/admin/sign-in')) {
        userStore.isUserNotAuth = true
      }
    }, [])
  
    useEffect(()=>{
      if (mainStore.saveDialogResult !== null) {
        mainStore.isSaveDialogOpen = false
        if (mainStore.saveDialogResult) {
          router.router(mainStore.routeUrlFromDialog)
          mainStore.isSaved = null
          mainStore.saveDialogResult = null
        }
      }
    }, [mainStore.saveDialogResult])
  
    useEffect(()=>{
        if (!mainStore.isConflictForce || mainStore.conflictType !== 'delete') {
            return
        }
        mainStore.deleteDialogResult = true
    }, [mainStore.isConflictForce])

    useEffect(()=>{
      if (mainStore.deleteDialogResult !== null) {
        const result = mainStore.deleteDialogResult;
        mainStore.deleteDialogResult = null
  
        mainStore.isDeleteDialogOpen = false
        if (result) {
          (
            async () => {
              await UniversalHttp.delete(
                mainStore.deleteDetails.instanceName, mainStore.deleteDetails.ids
              )
              mainStore.deleteEnded = true
            }
          )()
        }
      }
    }, [mainStore.deleteDialogResult])
  
  return (
      <div>
        <OnUserNotAuthentificatedPopup />
        <SaveAlertDialog />
        <DeleteAlertDialog />
        <ServerErrorSnackbar />
        <ValidationError />
        <OnConflictPopup />
        <OnLogoutDialog />
        <OnNotFound />
        <OnForbiddenPopup />
        <OnPublishSuccessPopup />
      </div>
    )
  }
  
  const pop = observer(AllPopUps)
  export {pop as AllPopUps}
  