import jwt_decode from "jwt-decode";
import userStore from '../../store/UserStore';

const TokenService = (function tokenService() {
    let service;
    function getServiceFunc() {
        if (!service) {
        service = this;
        return service;
        }
        return service;
    }

    const setToken = (tokenObj) => {
        if (tokenObj.access) {
            userStore.tokens.access = tokenObj.access;
        }
        if (tokenObj.refresh) {
            userStore.tokens.refresh = tokenObj.refresh;
        }
    };

    const getAccessToken = () => userStore.tokens.access;

    const getRefreshToken = () => userStore.tokens.refresh;

    const getTokenValidity = (tokenObj) => {
        const decodedToken = jwt_decode(tokenObj);
        const dateNow = new Date();
        const timeStamp = dateNow.getTime() / 1000;

        if (decodedToken?.exp < timeStamp) {
            return false;
        }
        return true;
    };

    const getAccessTokenValidity = () => {
        const accessToken = getAccessToken();
        if (accessToken) {
        return getTokenValidity(accessToken);
        }
        return null;
    };

    const getRefreshTokenValidity = () => {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
        return getTokenValidity(refreshToken);
        }
        return null;
    };

    const clearToken = () => {
        userStore.tokens = {access: '', refresh: ''}
    };

    return {
        getService: getServiceFunc,
        setToken,
        getAccessToken,
        getRefreshToken,
        getAccessTokenValidity,
        getRefreshTokenValidity,
        clearToken,
    };
})();

export default TokenService;