import { Button } from "@mui/material";
import React from "react";

export const FullWidthButton = ({ children, onClick, startIcon=null}) => {


    return (
        <Button 
            variant='outlined'
            onClick={onClick}
            fullWidth
            style={{
                textTransform: 'none',
            }}
            startIcon={startIcon}
        >
            {children}
        </Button>
    );
}