import { observer } from "mobx-react-lite"
import React, {  } from "react"
import { Information } from "../components/Information";
import { MainDesk } from "../components/MainDesk";
import { UniversalContent } from "../components/UniversalContent";
import { BiLanguageForm } from "../components/forms/BiLanguageForm";
import { InputTextField } from "../components/forms/InputTextField";
import { contentStore } from "../store/Store";
import { OptionsContainer } from "../styles/ContentStyles";
import { FileInput } from "../components/forms/FileInput";
import { MarkDown } from "../components/forms/MarkDown";
import { ListOfLinks } from "../components/ListOfLinks";
import { DoubleTextInput } from "../components/forms/DoubleTextInput";
import { ManyAutocomplete } from "../components/forms/ManyAutocomplete";
import { Relations } from "../components/Relations";
import { UniversalHttp } from "../services/http/UniversalHttp";
import { OneAutocomplete } from "../components/forms/OneAutocomplete";
import { GlobalBilanguageZone } from "../components/GlobalBilanguageToggler";
import { useState, useEffect } from 'react';
import { FormControlLabel, Switch, Typography } from "@mui/material";
import { Label } from '../components/Label';
import { mainStore } from "../store/MainStore";
import { toJS } from "mobx";

const Project = () => {
    const fetchProjectsOwners = async () => await UniversalHttp.get('projects-owners')
    const fetchArContent = async () => await UniversalHttp.get('ar-content/with-no-relations')
    const fetchTags = async () => await UniversalHttp.get('tags')
    const fetchCategories = async () => await UniversalHttp.get('categories')
    const fetchCities = async () => await UniversalHttp.get('cities')

    const linksStyles = !contentStore.item?.links?.items?.length ? {margin: '30px 0 20px 0'} : {margin: '0 0 20px 0'}
    useEffect(()=>{console.log()}, [contentStore?.item.hasAddress])

    const ProjectMainDesk = () => (
        <MainDesk numOfLeftChildren={2} keepTwoColumnsOnSecondItem={true}>
                <BiLanguageForm label="Name">
                    <InputTextField
                        required={true}
                        obj={contentStore.item?.title}
                    />
                </BiLanguageForm>
                <BiLanguageForm label="Description">
                    <MarkDown
                        height={250}
                        obj={contentStore.item?.description}
                    />
                </BiLanguageForm>
                <FileInput
                    type="image"
                    label='Images'
                    globalStoreSubValue={'images'}
                    obj={contentStore.item?.images}
                />
                <FileInput
                    type="video"
                    label='Videos'
                    globalStoreSubValue={'videos'}
                    obj={contentStore.item?.videos}
                />
                <ListOfLinks 
                    obj={contentStore.item?.links}
                    styles={linksStyles}
                />
                <FormControlLabel 
                    control={
                        <Switch 
                            checked={contentStore?.item.hasAddress}
                            onChange={(e)=>{
                                contentStore.item.hasAddress = e.target.checked;
                                if (!e.target.checked && (      
                                    contentStore.item.address?.city.item?.title.ru.value ||
                                    contentStore.item.address?.city.item?.title.eng.value ||    
                                    contentStore.item.address?.lat.value ||
                                    contentStore.item.address?.non.value ||
                                    contentStore.item.address?.displayText.ru.value || 
                                    contentStore.item.address?.displayText.eng.value)
                                ) mainStore.isSaved = false;
                            }}
                        />
                    }
                    sx={{margin: "10px 0 10px -10px"}} 
                    label={<Typography style={{fontWeight: 600, fontSize: 14}}>Has address</Typography>}
                />
                {contentStore?.item.hasAddress &&
                <BiLanguageForm label='Address'>
                    <OneAutocomplete 
                        fetchOptions={fetchCities}
                        obj={contentStore.item?.address?.city}
                        label='City'
                        isHelperText={true}
                    />
                    <DoubleTextInput
                        leftLabel={'Lat'}
                        isLeftNumeric={true}
                        isLeftRequired={true}
                        leftObj={contentStore.item?.address?.lat}
                        rightLabel={'Non'}
                        isRightNumeric={true}
                        isRightRequired={true}
                        rightObj={contentStore.item?.address?.non}
                    />
                    <InputTextField
                        required={true}
                        obj={contentStore.item?.address?.displayText}
                        label="Display Text"
                    />
                </BiLanguageForm>}
        </MainDesk>
    );

    const ProjectOptions = () => (
        <OptionsContainer>
            <Information 
                obj={contentStore.item}
            />
            <Relations>
                <ManyAutocomplete 
                    fetchOptions={fetchProjectsOwners}
                    obj={contentStore.item?.projectOwners}
                    label='Owners'
                />
                <ManyAutocomplete 
                    fetchOptions={fetchArContent}
                    obj={contentStore.item?.arcontents}
                    label='AR Content'
                />
                <ManyAutocomplete 
                    fetchOptions={fetchTags}
                    obj={contentStore.item?.tags}
                    label='Tags'
                />
                <ManyAutocomplete 
                    fetchOptions={fetchCategories}
                    obj={contentStore.item?.categories}
                    label='Categories'
                />
            </Relations>
        </OptionsContainer>
    )

    return (
        <UniversalContent 
            MainDeskComponent={ProjectMainDesk}
            OptionsContainerComponent={ProjectOptions}
        />
    )
}

const o = observer(Project)
export {o as Project}