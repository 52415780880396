import React, {} from 'react';
import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import { SmartRouter } from '../services/Router';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { TOOLBOX_ITEMS } from '../constants/ToolboxConstants';
import { useLocation } from 'react-router-dom';


function ToolboxItems(props) {
    const NUM_OF_MAIN_ITEMS = 3
    const router = new SmartRouter()
    const location = useLocation()

    const handleListItemClick = (path) => {
        router.to(path)
    };

    const ToolboxItem = ({ item }) => {
        const ToolboxTextStyled = styled(Typography)`
            font-size: 14px !important;
            font-weight: 600 !important;
            line-height: 20px !important;
            letter-spacing: 0px !important;
            margin-left: -40px !important;
        `

        return (
            <ListItem disablePadding>
                <ListItemButton
                    selected={
                        location.pathname===item.path || 
                        location.pathname.startsWith(`${item.path}/`)
                    }
                    onClick={() => {
                            handleListItemClick(item.path)
                    }}
                    disabled={item.name == 'Countries'}
                >
                    <ListItemIcon> 
                        <FiberManualRecordIcon style={{height: '5px'}}/> 
                    </ListItemIcon>
                    <ListItemText 
                        sx={{marginLeft: "8px"}} 
                        
                        primary={
                            <ToolboxTextStyled>
                                {item.name}
                            </ToolboxTextStyled>
                        } 
                    />
                </ListItemButton>
            </ListItem>
        );
    }

    return (
        <List component="nav" aria-label="main mailbox folders" sx={{padding: 0}}>
            {
                TOOLBOX_ITEMS.map((item)=>{
                    return <div>
                            <ToolboxItem item={item} />
                            {TOOLBOX_ITEMS.indexOf(item) + 1 === NUM_OF_MAIN_ITEMS ? <Divider /> : null}
                        </div>;
                    }
                )
            }
        </List>
    );
}

const ToolboxItemsObserver = observer(ToolboxItems)
export {ToolboxItemsObserver as ToolboxItems}
