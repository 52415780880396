import { Box, FormHelperText, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FileUploader } from "react-drag-drop-files";
import { MediaItem } from "../MediaItem";
import { FileItem } from "../FileItem";
import { mainStore } from "../../store/MainStore";

const imageExtensions = require('image-extensions');
const videoExtensions = require('video-extensions');

export function humanReadBytes(a,b=2){if(!+a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1000));return`${parseFloat((a/Math.pow(1000,d)).toFixed(c))} ${["Bytes","KiB","MiB","GiB","TiB","PiB","EiB","ZiB","YiB"][d]}`}

const fileTypes = (fileType) => {
    switch (fileType) {
        case 'image': 
            return imageExtensions
        case 'video':
            return videoExtensions
    }
}

export const fileToFileItem = (file) => {
    if (!file) return
    
    return {
        name: file.name, 
        size: file.size,
        url: URL.createObjectURL(file.file),
    }
}

const FileInput = ({
    type='image',
    multiple=true,
    label=" ",
    globalStoreSubValue,
    obj,
    disabled=false,
    handleDownloadedFiles=()=>{},
    handleDeletedItems=()=>{},
}) => {
    const [files, setFiles] = useState(window.FILES[globalStoreSubValue] || [])
    const [allItems, setAllItems] = useState([])
    const types = fileTypes(type)
    const theme = useTheme();

    if (!window.FILES?.details) {
        window.FILES.details = {}
    }  

    const fileItemsWithFakeUrl = files.length ? files.map(file => {
        return fileToFileItem(file)
    }) : []

    useEffect(()=>{
        const concatedItems = Array.prototype.concat(obj?.items, fileItemsWithFakeUrl)
        const itemsToSet = concatedItems.length ? multiple ? concatedItems : [concatedItems[concatedItems.length-1]] : []
        setAllItems(disabled ? [] : itemsToSet)

        if (!window?.FILES.hasOwnProperty(globalStoreSubValue)) {
            window.FILES[globalStoreSubValue] = []
        }
    }, [files, obj?.items])

    const addFiles = (newFiles) => {
        if (disabled) return;

        if (!multiple) {
            newFiles = [newFiles]
        } 

        let filesToState = multiple ? [...files] : []
        Array.prototype.forEach.call(newFiles, file => {
            mainStore.isSaved = false

            filesToState.push({
                name: file.name,
                size: file.size,
                file: file,
            })
        })
        if (filesToState.length && !multiple) obj.items = [];
        setFiles(filesToState)
        handleDownloadedFiles(filesToState)
        window.FILES[globalStoreSubValue] = filesToState
        obj.error = false
    }

    const onDownload = (url) => {
        if (url.startsWith('blob:')) {
            const itemsLength = obj.items.length
            const onlyFilesItems = allItems.slice(itemsLength)
            let indexOfFile=-1;
            onlyFilesItems.forEach(fileItem=>{
                    if (url == fileItem.url){
                        indexOfFile=onlyFilesItems.indexOf(fileItem)
                    }})
            const file = files[indexOfFile]
            

            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(file.file, file.name);
            }
            else{
                const elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(file.file);
                elem.download = file.name;        
                document.body.appendChild(elem);
                elem.click();        
                document.body.removeChild(elem);
            }
        } else {
            window.open(url)
        }
    }

    const onDelete = (url) => {
        obj.error = false
        mainStore.isSaved = false

        if (url.startsWith('blob:')) {
            if (!multiple) obj.items = [];

            const itemsLength = obj.items.length
            const onlyFilesItems = allItems.slice(itemsLength)
            let indexOfFile=undefined;
            onlyFilesItems.forEach(fileItem=>{
                    if (url == fileItem.url){
                        indexOfFile=onlyFilesItems.indexOf(fileItem)
                    }})
            const file = files[indexOfFile]

            const newFiles = files.filter(f => f !== file)
            setFiles(newFiles)
            window.FILES[globalStoreSubValue] = window.FILES[globalStoreSubValue].filter((item)=>item!==file) 
            handleDeletedItems(fileToFileItem(file))
        } else {
            handleDeletedItems(obj.items.filter( i => i.url === url )[0])
            obj.items = obj.items.filter( i => i.url !== url )
        }
    }

    const MainContainer = styled.div`
        width: 332px; 
    `
    
    const DropArea = () => {
        const DropAreaStyled = styled.div`
            height: 100px;
            border: ${disabled ? 'none' : obj?.error ? '2px dashed ' + theme.palette.error.main : '2px dashed ' + theme.palette.primary.main};
            border-radius: 10px;
            background: ${!disabled ? 'none' : 'repeating-linear-gradient(45deg, ' + theme.palette.background.paper+', '+theme.palette.background.paper+' 15px, '+theme.palette.background.paper+' 0, '+theme.palette.primary.main+' 30px)'};

            display: grid;
            align-items: center;
            justify-items: center;
            text-align: center;

            color: #969696;

            &:hover {
                cursor: ${!disabled ? 'pointer' : 'default'};
            }
        `

        return (
            <DropAreaStyled>
                    {!disabled && <p><b>Click</b> to select an asset <b>or drag and<br/>
                    drop</b> in this area</p>}
            </DropAreaStyled>
        )
    }

    const FilesList = styled(Box)`
        background-color: #252525;
        border: 1px solid ${obj?.error?theme.palette.error.main:'#969696'};
        border-radius: 10px;
        padding: 5px 0 5px 0;
        margin: 10px 0 0 0;
        display: grid;
        justify-content: ${ allItems?.length <= 1 ? "center" : "left" };
        grid-gap: 16px;
        padding: 16px;
        grid-auto-flow: column;
        grid-auto-columns: max-content;
        grid-template-rows: 100px;
        overflow-x: auto;
    `

        const Title = () => {
            const titleStyle = {
                fontSize: '12px',
                fontWeight: 700,
                marginTop: '8px',
                marginLeft: '2px',
                marginBottom: "5px",
            } 
            return (
                <Typography style={titleStyle}>{label}</Typography>
            );
        }

    const ItemComponent = ({item}) => type === 'file' ? 
        <FileItem item={item} onDelete={onDelete} onDownload={onDownload} /> 
            : 
        <MediaItem item={item} onDelete={onDelete} isImageNotVideo={type=="image"} />

    return (
        <div>
            <Title />
            <MainContainer>
                <FileUploader 
                    handleChange={addFiles} 
                    multiple={multiple}   
                    children={<DropArea />} 
                    types={types}
                    disabled={disabled}
                    hoverTitle=' '
                    dropMessageStyle={    
                        disabled ? {filter: 'alpha(opacity=0)', opacity: '0.0'} : {}
                    }
                />
                {
                    allItems?.length!==0 && 
                    <FilesList>
                        {allItems?.map((item)=>{
                            return <ItemComponent item={item}/>
                        })}
                    </FilesList>
                }
            </MainContainer>
            <FormHelperText error={true} >{obj?.error||" "}</FormHelperText>
        </div>
    );
}

const observedFileInput = observer(FileInput)
export {observedFileInput as FileInput};