import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { mainStore } from "../../store/MainStore";

const SimpleSelect = ({
        language='', 
        obj, 
        label='', 
        options=[], 
        defaultValue=undefined,
        blockLanguage=false,
        optionsValuesTitles=false,
    }) => {
    if (blockLanguage) language = '';

    const getValue = () => _.get(obj, `${language}.value`, obj?.value)
    const getError = () => obj?.error
    const setValue = (value) => {
        try {
            obj[language].value = value
        } catch {
            obj.value = value
        }
    }

    const setError = (error) => {
        obj.error = error
    }

    const labelId = `simple-select-label-${label}`

    const handleChange = (event) => {
        setValue(event.target.value);
        setError(false)
        mainStore.isSaved = false
    };

    return (
        <FormControl size='small' fullWidth>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                labelId={labelId}
                value={getValue()}
                placeholder={label}
                onChange={handleChange}
                error={getError()}
                defaultValue={defaultValue}
            >   
                {options.map((item)=>{
                    return <MenuItem value={item}>
                        {optionsValuesTitles ? optionsValuesTitles[item] : item}
                    </MenuItem>
                })}
            </Select>
            <FormHelperText error={true} style={{marginLeft: 0}}>{getError()||" "}</FormHelperText>
        </FormControl>
    );
}

const observedSimpleSelect = observer(SimpleSelect)
export {observedSimpleSelect as SimpleSelect};