import React from "react"
import { contentStore } from "../store/Store"
import { ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { observer } from "mobx-react-lite"

// const GlobalBilanguageZone = ({ children }) => {
//     const renderChildren = () => React.Children.map(children, child => {
//         return React.cloneElement(child, {
//           language: 
//         })
//     })

//     return renderChildren()
// }

const GlobalLanguageToggler = () => {
    const theme = useTheme()
    return <div style={{display: 'grid'}}>
        <ToggleButtonGroup
            color='primary'
            value={contentStore.globalLanguage}
            onChange={(e, value)=>{
                contentStore.globalLanguage = value
            }}
            exclusive
            aria-label="text alignment"
            size='small'
            style={{justifySelf: 'end', alignSelf: 'end', margin: "0 13px -30px 0", height: 35, padding: 5, outline: contentStore.globalLanguage ? `2px solid #58a15e`: 'none'}}
        >
            <ToggleButton value={'ru'} style={{width: 50}} disabled={contentStore.globalLanguage=='ru'} sx={{backgroundColor: contentStore.globalLanguage == 'ru' ? `${theme.palette.divider} !important` : 'none'}}>
                RU
            </ToggleButton>
            <ToggleButton value={'eng'} style={{width: 50}} disabled={contentStore.globalLanguage=='eng'} sx={{backgroundColor: contentStore.globalLanguage == 'eng' ? `${theme.palette.divider} !important` : 'none'}}>
                ENG
            </ToggleButton>
        </ToggleButtonGroup>
    </div>
}


// const o = observer(GlobalBilanguageZone)
// export {o as GlobalBilanguageZone}

const oo = observer(GlobalLanguageToggler)
export {oo as GlobalLanguageToggler}