import React from 'react';
import { Typography} from '@mui/material';
import styled from 'styled-components';
import { ToolboxItems } from '../Toolbox';

function ToolboxBody(props) {
    const CollectionTypesStyled = styled(Typography)`
        font-size: 11px !important;
        font-weight: 700 !important;
        line-height: 16px !important;
        letter-spacing: 0px !important;
        margin-left: 24px !important;
    `
    const CollectionTypesTextStyled = styled.div`
        height: 64px !important;
        display: grid;
        align-content: center;
    `

    const CollectionTypesText = () => {
        return (
            <CollectionTypesTextStyled>
                <CollectionTypesStyled>
                    COLLECTION TYPES
                </CollectionTypesStyled>
            </CollectionTypesTextStyled>
        );
    } 

    return (
        <div>
            <CollectionTypesText />
            <ToolboxItems />
        </div>
    );
}

export default ToolboxBody;