import React, { useEffect } from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import MainPage from "./pages/MainPage";
import StartPage from "./pages/StartPage";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {observer} from "mobx-react-lite";
import { mainStore } from "./store/MainStore";
import { ItemsList } from "./components/ItemsList";
import { Tag } from "./pages/Tag";
import { Category } from "./pages/Category";
import { ArContent } from "./pages/Arcontent";
import { ProjectOwner } from "./pages/ProjectOwner";
import { Project } from "./pages/Project";
import { City } from "./pages/Cities";
import { User } from "./pages/User";
import { SignIn } from "./pages/SignIn";
import { contentStore } from "./store/Store";
import { AllPopUps } from "./components/popups.js/AllPopups";

function App() {
    const theme = mainStore.theme
    window.PATH_PREFIX = '/admin'
    window.FILES = {}

    const location = useLocation()
    
    useEffect(()=>{
        contentStore.globalLanguage = null
    }, [location.pathname])
        

    const darkTheme = createTheme({
        palette: {
            mode: theme,
            primary: {
                main: '#50FF61',
                disabled: '#969696',
            },
            secondary: {
                main: '#f50057',
            },
            background: {
                default: '#242424',
                paper: '#2C2C2C',
            },
        },
        typography: {
            fontFamily: "'SF Pro Display', -apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
        },
    });

    const lightTheme = createTheme({
        palette: {
            mode: theme,
            primary: {
                main: '#38B243',
                disabled: '#969696',
            },
            secondary: {
                main: '#f50057',
            },
            background: {
                default: '#ffffff',
                paper: '#E8E8E8',
                datagrid: '#F9F9F9',
            },
        },
        typography: {
            fontFamily: "'SF Pro Display', -apple-system,BlinkMacSystemFont,Roboto,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol'",
        },
    });


    return (
        <ThemeProvider theme={mainStore.theme === 'dark' ? darkTheme : lightTheme}>
            <CssBaseline enableColorScheme/>
            <div className="App">
                <AllPopUps />
                <Routes>
                    <Route path='/admin/sign-in' element={<SignIn />} />
                    <Route path="/admin" element={<MainPage />} render={()=>{alert(123); return}} >
                            <Route path="projects-owners">
                                <Route path="" element={<ItemsList />} />
                                <Route path="create" element={<ProjectOwner />} />
                                <Route path=":id" element={<ProjectOwner />} />
                            </Route>
                            <Route path="projects" >
                                <Route path="" element={<ItemsList/>} />
                                <Route path="create" element={<Project />} />
                                <Route path=":id" element={<Project />} /> 
                            </Route>
                            <Route path="ar-content" >
                                <Route path="" element={<ItemsList />} />
                                <Route path="create" element={<ArContent />} />
                                <Route path=":id" element={<ArContent />} />
                            </Route>
                            <Route path="tags" >
                                <Route path="" element={<ItemsList />} />
                                <Route path="create" element={<Tag />} />
                                <Route path=":id" element={<Tag />} />
                            </Route>
                            <Route path="categories" >
                                <Route path="" element={<ItemsList />} />
                                <Route path="create" element={<Category />} />
                                <Route path=":id" element={<Category />} />
                            </Route>
                            <Route path="cities" >
                                <Route path="" element={<ItemsList />} />
                                <Route path="create" element={<City />} />
                                <Route path=":id" element={<City />} />
                            </Route>
                            <Route path="users" >
                                <Route path="" element={<ItemsList />} />
                                <Route path="create" element={<User />} />
                                <Route path=":id" element={<User />} />
                            </Route>
                            <Route path="" element={<StartPage />} />
                            <Route path="*" element={<Navigate to='/admin'/>} />
                    </Route>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

const appObserver = observer(App);
export {appObserver as App};
