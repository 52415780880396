import React from 'react';
import styled from "styled-components";
import ToolboxBody from "./ToolboxBody";
import ToolboxFooter from "./ToolboxFooter";
import { ToolboxHead } from './ToolboxHead';
import { Box, useTheme } from '@mui/material';

function Toolbox(props) {
    const theme = useTheme()

    const Toolbox = styled(Box)`
      display: grid;
      grid-template-rows: 63px auto 70px;
      border-right: 0.05em solid ${theme.palette.divider};
    `

    return (
        <Toolbox>
            <ToolboxHead />
            <ToolboxBody />
            <ToolboxFooter />
        </Toolbox>
    );
}

export default Toolbox;