import { TextField, Typography, FormHelperText } from "@mui/material"
import _ from "lodash"
import { observer } from "mobx-react-lite"
import React from "react"
import { mainStore } from "../../store/MainStore"

const InputTextField = ({
    type='', 
    readOnly=false, 
    language='', 
    obj, 
    label='', 
    blockLanguage=false, 
    required=false, 
    isNumeric=false, 
    helperTextIfNoError=''
}) => {
    if (blockLanguage) language = '';

    const TEXT_STYLE = {
        fontSize: '14px',
        fontWeight: 400,
    }

    const getValue = () => _.get(obj, `${language}.value`, obj?.value)
    const getError = () => obj?.error
    const setValue = (value) => {
        try {
            obj[language].value = value
        } catch {
            obj.value = value
        }
    }

    const setError = (error) => {
        obj.error = error
    }

    return (
        <div>
            <TextField 
                size='small'
                autoComplete='new-password'
                onChange={(e)=>{
                    if (isNumeric&&!e.target.value.match(/^-?(?!0{2,})(?!^0[0-9])(?:0?|[0-9]*)\.?[0-9]*$/)) return; 
                    mainStore.isSaved = false
                    setValue(e.target.value)
                    setError(false)
                }}
                required={required}
                sx={{width: '100%'}}
                value={type==='password'&&readOnly?'12345678':getValue()}
                error={getError()}
                label={required ? label ? label : 'Required' : label}
                InputProps={{
                    readOnly: readOnly,
                }}
                type={type}
            >
                <Typography style={TEXT_STYLE}>
                    {getValue()}
                </Typography>
            </TextField>
            <FormHelperText error={!helperTextIfNoError} >{getError()|| helperTextIfNoError || " "}</FormHelperText>
        </div>
    );
}

const observedInputTextField = observer(InputTextField)
export {observedInputTextField as InputTextField};