import {makeObservable, observable} from "mobx";

class ContentStore {
    item={};
    items=[];
    globalLanguage='ru'

    constructor () {
        makeObservable(this, {
            item: observable,
            items: observable,
            globalLanguage: observable,
        });
    }

    __str__=' ';

    initItem = (initItem) => {
        const item  = initItem
        
        this.item = item

        window.FILES = {}
    }

    initItems = (initItems) => {
        const items = initItems
        this.items = items
    }
}

export const contentStore = new ContentStore()
