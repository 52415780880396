import { ARCONTENT_INIT, ITEM_TEMPLATE } from "../../constants/StoreConstants"

const STANDART_BILANGUAGE_UXCLUDE_LIST = [
    'isPublished', 
    'id', 
    'updatedBy', 
    'createdBy', 
    'updatedAt',
    'createdAt',
    ['displayImage', 'url'],
    ['displayImage', 'size'],
    ['displayImage', 'name'],
    ['config', 'url'],
    ['config', 'size'],
    ['config', 'name'],
    ['iconImage', 'url'],
    ['iconImage', 'size'],
    ['iconImage', 'name'],
    ['videos', 'url'],
    ['videos', 'size'],
    ['videos', 'name'],
    ['images', 'url'],
    ['images', 'size'],
    ['images', 'name'],
    ['image', 'url'],
    ['image', 'size'],
    ['image', 'name'],
    ['model', 'url'],
    ['model', 'size'],
    ['model', 'name'],
    ['target', 'url'],
    ['target', 'size'],
    ['target', 'name'],
]

const is_in_exclude_list = (key, parents=[]) => {
    var isExcludingKey = false
    parents = [...parents, key]

    STANDART_BILANGUAGE_UXCLUDE_LIST.forEach(
        itemsToCheck =>
            {
                let cleanItemsToCheck = typeof itemsToCheck === 'string' ? [itemsToCheck, ] : itemsToCheck
                if (cleanItemsToCheck.length === parents.length && cleanItemsToCheck.every(function(value, index) { return value === parents[index]})) isExcludingKey = true;
            }
    )

    return isExcludingKey
}

export class Serializer {
    adminJson;
    backendJson;

    fromAdminToBackend = (adminJson=this.adminJson) => {
        let backendJson = {}

        const windowFILESobj = window?.FILES || {} 

        if (Array.isArray(adminJson)) {
            let newArray = []
            for (let index=0; index < adminJson.length; index++) {
                newArray.push(this.fromAdminToBackend(adminJson[index]))
            }
            return newArray
        }
  
        for (let key in adminJson) {
            if (Array.isArray(adminJson[key])){
                let newArray = this.fromAdminToBackend(adminJson[key])
                backendJson[key] = newArray
            } else if (typeof adminJson[key] === 'object' && adminJson[key] !== null) {
                if (adminJson[key].hasOwnProperty('value')) {
                    backendJson[key] = adminJson[key].value
                } else if (adminJson[key].hasOwnProperty('item')) {
                    if (adminJson[key].item) {
                        backendJson[key] = this.fromAdminToBackend(adminJson[key].item)
                    } else {
                        backendJson[key] = null
                    }
                }else if (adminJson[key].hasOwnProperty('items')) {
                    if (windowFILESobj.hasOwnProperty(key)) {
                        if (!windowFILESobj[key].length && !adminJson[key].items.length) {
                            backendJson[key] = false
                        } else {
                            backendJson[key] = this.fromAdminToBackend(adminJson[key].items)
                        }
                    } else {
                        backendJson[key] = this.fromAdminToBackend(adminJson[key].items)
                    }
                } else {
                    backendJson[key] = this.fromAdminToBackend(adminJson[key])
                }
            } else if (key === 'error') {
            } else {
                backendJson[key] = adminJson[key]
            }
        }
        return backendJson  
    }

    fromBackendToAdmin = (backendJson, parents=[]) => {
        let adminJson = {}

        if (Array.isArray(backendJson)) {
            let newArray = []
            for (let index=0; index < backendJson.length; index++) {
                newArray.push(this.fromBackendToAdmin(backendJson[index], parents))
            }
            return newArray
        }

        for (let key in backendJson) {
            if (Array.isArray(backendJson[key])){
                const newArray = this.fromBackendToAdmin(backendJson[key], [...parents, key])
                adminJson[key] = {items: newArray}
            } else if (key==='project' || key==='city') {
                if (backendJson[key]) {
                    adminJson[key] = {item: this.fromBackendToAdmin(backendJson[key])}
                } else {
                    adminJson[key] = ITEM_TEMPLATE
                }
            } else if (typeof backendJson[key] == 'object' && backendJson[key] !== null) {
              if (backendJson[key].hasOwnProperty('ru')) {
                adminJson[key] = {
                  ru: {value: backendJson[key].ru},
                  eng: {value: backendJson[key].eng},
                  error: false,
                }
              } else {
                adminJson[key] = this.fromBackendToAdmin(backendJson[key], [...parents, key])
              }
            } else {
              if (!is_in_exclude_list(key, parents)) {
                adminJson[key] = {
                  value: backendJson[key], 
                  error: false
                }
              } else {
                adminJson[key] = backendJson[key]
              }
            }
        }
        return adminJson
    }
}