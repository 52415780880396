import React, {  } from "react";
import { observer } from "mobx-react-lite";
import { UniversalContent } from "../components/UniversalContent";
import { Information } from "../components/Information";
import { MainDesk } from "../components/MainDesk";
import { BiLanguageForm } from "../components/forms/BiLanguageForm";
import { InputTextField } from "../components/forms/InputTextField";
import { contentStore } from "../store/Store";
import { OptionsContainer } from "../styles/ContentStyles";

const Tag = () => {
    const TagMainDesk = () => (
        <MainDesk numOfLeftChildren={3}>
                <BiLanguageForm label="Name">
                    <InputTextField
                        required={true}
                        obj={contentStore.item?.title}
                    />
                </BiLanguageForm>
        </MainDesk>
    );

    const TagOptions = () => (
        <OptionsContainer>
            <Information 
                obj={contentStore.item}
            />
        </OptionsContainer>
    )

    return (
        <UniversalContent 
            MainDeskComponent={TagMainDesk}
            OptionsContainerComponent={TagOptions}
        />
    )
}

const o = observer(Tag)
export {o as Tag}