import { ToggleButtonGroup, ToggleButton, Paper } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Label } from '../Label';
import styled from 'styled-components';
import { mainStore } from '../../store/MainStore';

const BigToggle = ({obj, label, disabled=false}) => {
    const MARGIN = 3

    const TextStyled = styled.div`
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
    `

    const buttonStyle = {
        width: 160, 
        margin: `${MARGIN}px 0 ${MARGIN}px 0`,
        border: 'none !important',
    }

    const activeButtonStyle = {
        ...buttonStyle,
        backgroundColor: (theme) => `${theme.palette.background.paper} !important`,
        outline: (theme) => disabled ? `1px solid #cccccc` : `1px solid ${theme.palette.primary.main}`,
        color: disabled ? '#cccccc !important' : ',',
        border: 'none',
    }


    const paperStyle = {
        width: buttonStyle.width * 2 + MARGIN * 2 + 1,
        border: (theme) => `1px solid ${theme.palette.divider}`
    }

    const getValue = () => obj?.value
    const getError = () => obj?.error
    const setValue = (value) => {
        if (value != null) {
            obj.value = value   
        }
    }

    const setError = (error) => {
        obj.error = error
    }

    return (
        <Label text={'Has mask'}>
            <Paper sx={paperStyle}>
                <ToggleButtonGroup
                    disabled={disabled}
                    color='primary'
                    value={getValue()}
                    onChange={(e, value)=>{
                        setValue(value)
                        setError(false)
                        mainStore.isSaved = false
                        return
                    }}
                    exclusive
                    aria-label="text alignment"
                    size='small'
                >
                    <ToggleButton value={true} sx={
                            getValue() ? {
                                ...activeButtonStyle, 
                                marginLeft: `${MARGIN}px`
                            } : {
                                ...buttonStyle,
                                marginLeft: `${MARGIN}px`
                            }}
                    >
                        <TextStyled>TRUE</TextStyled>
                    </ToggleButton>
                    <ToggleButton value={false} sx={
                        getValue() ? buttonStyle : activeButtonStyle
                    }>
                        <TextStyled>FALSE</TextStyled>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Paper>
        </Label>
    );
}

const observedBigToggle = observer(BigToggle)
export {observedBigToggle as BigToggle};