import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, IconButton, Stack, Typography, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SmartRouter } from '../services/Router';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { contentStore } from '../store/Store';
import { adminAxios } from '../services/http/adminAxios';
import { toJS } from 'mobx';
import { UniversalHttp } from '../services/http/UniversalHttp';
import { mainStore } from '../store/MainStore';
import { formatDate } from './Information';
import { BY_INSTANCE_NAME_CONSTANTS } from '../constants/StoreConstants';

const ListBodyHeadNotObserved = () => {
    const ListBodyHeadStyle = styled.div`
        height: 136px;
        width: 100%;
        display: grid;
        grid-template-columns: 998px 210px; 
    `

    const ListBodyTitleStyle = styled.div`
        height: 60px;
        display: grid;
        grid-template-rows: 38px 20px;
        margin: 36px 0 0 120px;
    `

    const ListTitleStyle = styled(Typography)`
        font-size: 32px !important;
        font-weight: 700 !important;
        line-height: 40px !important;
        letter-spacing: 0px !important;
    `

    const NumOfEntriesStyle = styled(Typography)`
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        letter-spacing: 0px !important;
    `

    const AddEntryButton = () => {
        const router = new SmartRouter()

        const TypographyStyled = styled(Typography)`
            font-size: 14px !important;
            font-weight: 700 !important;
            line-height: 16px !important;
            letter-spacing: 0px !important;
            padding: 5px 3px 5px import { Typography } from '@mui/material';
3px;

        `

        const handleAddButtonClick = () => {
            router.to(`create`)
        }

        return (
            <Button 
                variant='contained' 
                startIcon={<AddIcon />}
                style={{textTransform: 'none'}}
                onClick={handleAddButtonClick}
            >
                <TypographyStyled>Add new entry</TypographyStyled>
            </Button>
        );
    }

    const AddEntryBlock = () => {
        const AddEntryBlockStyle = styled.div`
            margin: 40px 0 0 0;
        `
        return <AddEntryBlockStyle><AddEntryButton /></AddEntryBlockStyle>
    }

    return (
        <ListBodyHeadStyle>
            <ListBodyTitleStyle>
                <ListTitleStyle>
                    <ListTitleStyle>{contentStore?.__str__ || " "}</ListTitleStyle>
                </ListTitleStyle>
                <NumOfEntriesStyle>
                    <NumOfEntriesStyle>{contentStore.items.length} entries found</NumOfEntriesStyle>  
                </NumOfEntriesStyle>
            </ListBodyTitleStyle>
            <AddEntryBlock />
        </ListBodyHeadStyle>
    );
};

const ListBodyHead = observer(ListBodyHeadNotObserved)

function ItemsList() {
    const router = new SmartRouter()
    const location = useLocation()
    const instanceName = location.pathname.split('/').slice(-1)[0]
    const theme = useTheme()

    const [restoreItems, setRestoreItems] = useState(false)

    const ListBodyContainer = styled.div`
        margin-left: 120px;
        width: 1032px;
    `

    const ListOfEntriesNotObserved = () => {
        const [rows, setRows] = useState([])
        const [rowSelectionModel, setRowSelectionModel] = useState([])

        const deleteSelectedRows = (ids) => {
            mainStore.deleteDetails = {instanceName, ids}
            mainStore.isDeleteDialogOpen = true
        }

        useEffect(()=>{
            setRestoreItems(false)
            contentStore.initItems([])
            mainStore.deleteEnded = false
            contentStore.__str__ = BY_INSTANCE_NAME_CONSTANTS[instanceName].title

            adminAxios.get(instanceName)
                .then(
                    (res)=>{
                        const data = res.data
                        contentStore.initItems(data)
                    }
                )
        }, [null, restoreItems, mainStore.deleteEnded])

        useEffect(()=>{
            setRows(toJS(contentStore.items))
        }, [contentStore.items])

        const DeleteSelectedButton = () => {
            return (
                <IconButton
                        disabled={rowSelectionModel.length === 0}
                        onClick={()=>deleteSelectedRows(rowSelectionModel)}
                        style={{textTransform: "none"}}
                        size='small'
                        color='secondary'
                ><DeleteIcon /></IconButton>
            )
        }

        const deleteRowButton = (params) => {
            return (
                <IconButton onClick={()=>deleteSelectedRows([params.row.id, ])}>
                    <DeleteIcon />
                </IconButton>
            );
        }

        const editRowButton = (params) => {
            const onEditClick = () => {
                const rowId = params.row.id
                
                router.to(String(rowId))
            }

            return <IconButton onClick={onEditClick}><EditIcon /></IconButton> 
        }

        const renderHeader = (headerName) => {
            const headerStyle = {
                'fontSize': '11px',
                'fontWeight': '700',
            }

            return <Typography style={headerStyle}>{headerName}</Typography>
        }

        const columns = [
            {
                field: 'updatedAt',
                valueGetter: (params) => Date.parse(params.row.updatedAt), 
                renderCell: (params) => {
                    return <div>{formatDate(params.row.updatedAt)}</div>
                },
                width: 180,
                headerName: 'Updated At',
                renderHeader: () => renderHeader('UPDATED AT'),
            },
            {
                field: 'createdAt',
                valueGetter: (params) => Date.parse(params.row.createdAt), 
                renderCell: (params) => {
                    return <div>{formatDate(params.row.createdAt)}</div>
                },
                width: 180,
                headerName: 'Created At',
                renderHeader: () => renderHeader('CREATED AT'),
            },
            {
                field: 'id', 
                headerName: 'id', 
                width: 180, 
                renderHeader: () => renderHeader('ID')
            },
            {
                field: 'title', 
                valueGetter: (params) => params.row.title.ru, 
                headerName: 'Title', 
                width: 200 + (instanceName=='users' ? 370 : 0), 
                renderHeader: () => renderHeader('TITLE')
            },
            (instanceName != 'users') && {
                field: 'isPublished', 
                headerName: 'Status', 
                renderHeader: () => renderHeader('STATUS'),
                width: 455,
                renderCell: (params) => {
                    const isPublished = params.row.isPublished

                    const publishStyle = {
                        'font-size': '14px',
                        'font-weight': '600',
                    }
                    const draftStyle = {
                        'font-size': '14px',
                        'font-weight': '400',
                    }
                    return (
                        <div>{isPublished?
                            <Typography color={'primary.main'} style={publishStyle}>Published</Typography>
                            :
                            <Typography style={draftStyle}>Draft</Typography>}
                        </div>
                    )
                }
            },
            {
                field: 'action',
                headerName: '',
                width: 120,
                disableColumnMenu: true,
                sortable: false,
                disableClickEventBubbling: true,
                
                renderCell: (params) => {
                    return (
                      <Stack direction="row" spacing={2}>
                        {editRowButton(params)}
                        {deleteRowButton(params)}
                      </Stack>
                    );
                },
              }
        ]

        const PublishSelectedButton = () => {
            const publishHandler = async (isPublished=true) => {
                await UniversalHttp.putIsPublishedOnServer(
                    instanceName, 
                    rowSelectionModel, 
                    isPublished
                )

                setRestoreItems(true)
            }

            return (
                <Box 
                    sx={{
                        height: 30, 
                        padding: 0.2, 
                        border: 2, 
                        borderRadius: 1, 
                        borderColor: rowSelectionModel.length === 0 ? '#666666':'primary.main'}}
                        
                >
                    <Button 
                        disabled={rowSelectionModel.length === 0}
                        sx={{height: 25}} 
                        size='small' 
                        onClick={()=>publishHandler(true)}
                    >Publish</Button> 
                    <Button 
                        disabled={rowSelectionModel.length === 0}
                        sx={{height: 25,}} 
                        color='warning' 
                        size='small' 
                        onClick={()=>publishHandler(false)}
                    >Draft</Button> 
                </Box>
            );
        }

        const CustomGridToolbar = () => {
            const excludePublishAndDeleteSelected = [
                'projects',
                'projects-owners',
                'ar-content',
                'cities', 
                'users',
            ]
            const disapplyDeleteAndPublishSelected = !excludePublishAndDeleteSelected.includes(instanceName)
            const quickFilterWidth = !disapplyDeleteAndPublishSelected ? 700 : 540

            return (
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarQuickFilter style={{width: quickFilterWidth}}/>
                    {disapplyDeleteAndPublishSelected && <PublishSelectedButton />}
                    {disapplyDeleteAndPublishSelected && <DeleteSelectedButton />}
                </GridToolbarContainer>
            );
        }

        const [sortModel, setSortModel] = useState(
            [{ field: 'updatedAt', sort: 'desc' }]
          );

        const [columnVisibilityModel, setColumnVisibilityModel] = useState(
            {
                updatedAt: false,
                createdAt: false,
            }
        );
        return (
            <DataGrid 
                columnVisibilityModel= {columnVisibilityModel}
                onColumnVisibilityModelChange={(model)=>{setColumnVisibilityModel(model)}}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                columns={columns}
                checkboxSelection
                density='compact'
                sx={{backgroundColor: theme.palette.background.datagrid}}
                rows={rows}
                rowHeight={48}
                style={{ minHeight: 400, width: '100%' }}
                slots={{
                    toolbar: CustomGridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                onRowSelectionModelChange={setRowSelectionModel}
                rowSelectionModel={rowSelectionModel}
                initialState={{
                    pagination: {paginationModel: {pageSize: 10}},
                    }}
                pageSizeOptions={[10, 20, 50]}
            />
        );
    }
    const ListOfEntries = observer(ListOfEntriesNotObserved)

    const ListBody = () => { 
        return (
            <ListBodyContainer>
                <ListOfEntries />
            </ListBodyContainer>
        );
    }
    
    return (
        <div>
            <ListBodyHead/>
            <ListBody />
        </div>
    );
}


export {ItemsList};