import { observer } from "mobx-react-lite"
import React, {  } from "react"
import { Information } from "../components/Information";
import { MainDesk } from "../components/MainDesk";
import { UniversalContent } from "../components/UniversalContent";
import { BiLanguageForm } from "../components/forms/BiLanguageForm";
import { InputTextField } from "../components/forms/InputTextField";
import { contentStore } from "../store/Store";
import { OptionsContainer } from "../styles/ContentStyles";
import { FileInput } from "../components/forms/FileInput";
import { MarkDown } from "../components/forms/MarkDown";
import { ListOfLinks } from "../components/ListOfLinks";
import { Desk } from "../components/Desk";
import { ManyAutocomplete } from "../components/forms/ManyAutocomplete";
import { UniversalHttp } from "../services/http/UniversalHttp";
import { GlobalBilanguageZone } from "../components/GlobalBilanguageToggler";

const ProjectOwner = () => {
    const fetchProjects = async () => await UniversalHttp.get('projects')

    const linksStyles = !contentStore.item?.links?.items?.length ? {margin: '30px 0 20px 0'} : null

    const ProjectOwnerMainDesk = () => (
        <MainDesk numOfLeftChildren={2} keepTwoColumnsOnSecondItem={true}>
            <BiLanguageForm label="Name">
                <InputTextField
                    required={true}
                    obj={contentStore.item?.title}
                />
            </BiLanguageForm>
            <BiLanguageForm label="Description">
                <MarkDown
                    height={250}
                    obj={contentStore.item?.description}
                />
            </BiLanguageForm>
            <FileInput
                type="image"
                multiple={false}
                label='Display Image*'
                globalStoreSubValue={'displayImage'}
                obj={contentStore.item?.displayImage}
            />
            <FileInput
                type="image"
                multiple={false}
                label='Icon Image*'
                globalStoreSubValue={'iconImage'}
                obj={contentStore.item?.iconImage}
            />
            <ListOfLinks 
                obj={contentStore.item?.links}
                styles={linksStyles}
            />
        </MainDesk>
    );

    const ProjectOwnerOptions = () => (
        <OptionsContainer>
            <Information 
                obj={contentStore.item}
            />
            <Desk>
                <ManyAutocomplete 
                    fetchOptions={fetchProjects}
                    obj={contentStore.item?.projects}
                    label='Projects'
                />
            </Desk>
        </OptionsContainer>
    )
    
    return (
        <UniversalContent 
            MainDeskComponent={ProjectOwnerMainDesk}
            OptionsContainerComponent={ProjectOwnerOptions}
        />
    )
}

const o = observer(ProjectOwner)
export {o as ProjectOwner}