import axios from "axios";
import { mainStore } from "../../store/MainStore";
import userStore from "../../store/UserStore";
import TokenService from "./TokenService";

const adminAxios = axios.create()

if (window.location.protocol + "//" + window.location.host === "http://localhost:3000") {
  adminAxios.defaults.baseURL = "http://localhost:8000/api/admin";
} else {
  adminAxios.defaults.baseURL = `${window.location.protocol}//${window.location.host}/api/admin`
}

adminAxios.interceptors.request.use(
  (config) => {
    const token = userStore.tokens.access;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

adminAxios.interceptors.response.use(
  (response) => {
    mainStore.isInternalServerError = false

    if (response.status == 250) {
      mainStore.publishSuccessText = response.data.message
      mainStore.isPublishSuccessPopup = true
    }

    return response
  },
  (error) => {
    const originalRequest = error.config;
    const valid = TokenService.getRefreshTokenValidity();
    
    if (!valid) {
      userStore.isUserNotAuth = true
    }

    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      return adminAxios({
        url: adminAxios.defaults.baseURL?.slice(0, -5) + 'token/refresh',
        method: 'post',
        data: {
          refresh: TokenService.getRefreshToken(),
        },
      }).then((res) => {
        if (res.status === 200) {
          TokenService.setToken(res.data);

          adminAxios.defaults.headers.common.Authorization = `Bearer ${TokenService.getAccessToken()}`;

          return adminAxios(originalRequest);
        }
        return null;
      }).catch(()=>{
        if (window.location.pathname.endsWith('/admin/sign-in')) {
          userStore.authError = true
        } else {
          userStore.isUserNotAuth = true
        }
      })
    }

    mainStore.stopLoading = true
    if (~~error.response.status/100>>0===5) {
      mainStore.isInternalServerError = true
    } else if (error.response.status === 422) {
      mainStore.isValidationError = true
    } else if (error.response.status === 409) {
      mainStore.conflictType = error.response.data.type;
      mainStore.conflictText = error.response.data.message
      mainStore.isConflictDialog = error.response.data.raiseDialog
      mainStore.isConflictForce = false
      mainStore.isConflictPopup = true

      if (error.response.data.type == 'delete') {
        mainStore.deleteEnded = false
      }
    } else if (error.response.status === 403) {
      mainStore.forbiddenError = true
    }
    
    return Promise.reject(error);
  }
);

export { adminAxios };
