import React, { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { observer } from "mobx-react-lite";
import { BY_INSTANCE_NAME_CONSTANTS, PROJECT_INIT } from "../constants/StoreConstants";
import { SmartRouter } from "../services/Router";
import { UniversalHttp } from "../services/http/UniversalHttp";
import { mainStore } from "../store/MainStore";
import { contentStore } from "../store/Store";
import { ContentContainer } from "../styles/ContentStyles";
import { ContentHead } from "./ContentHead";
import { toJS } from "mobx";

const UniversalContent = ({ MainDeskComponent, OptionsContainerComponent=null, createOnly=false }) => {
    let { id } = useParams()
    OptionsContainerComponent = OptionsContainerComponent ? OptionsContainerComponent : ()=><></>

    const router = new SmartRouter()
    const location = useLocation()
    const instanceName = location.pathname.split('/').slice(-2)[0]

    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isPublishLoading, setIsPublishLoading] = useState(false)
    const [restoreItem, setRestoreItem] = useState(false)

    useEffect(() => {
        setRestoreItem(false)

        if (!id) {
            contentStore.initItem(BY_INSTANCE_NAME_CONSTANTS[instanceName].initItem)
        } else {
            contentStore.initItem(BY_INSTANCE_NAME_CONSTANTS[instanceName].initItem);
            UniversalHttp.get(instanceName, id)
                .then((res)=>{
                    contentStore.initItem(res)
                    if (res.hasOwnProperty('address') && 
                        (res.address?.city.item?.title.ru.value ||
                        res.address?.city.item?.title.eng.value ||    
                        res.address?.lat.value ||
                        res.address?.non.value ||
                        res.address?.displayText.ru.value || 
                        res.address?.displayText.eng.value)) contentStore.item.hasAddress = true
                })
                .catch((error)=>{
                    if (error.response.status === 404) {
                        mainStore.isNotFound = true
                    }
                })
            contentStore.__str__ = BY_INSTANCE_NAME_CONSTANTS[instanceName].title;
        }

        mainStore.isSaved = null
    }, [id, null, restoreItem])

    useEffect(()=>{
        if (!mainStore.isConflictForce || mainStore.conflictType !== 'publish') {
          return
        }
    
        setIsPublishLoading(true);
    
        UniversalHttp.putIsPublishedOnServer(
            instanceName, 
            id, 
            !contentStore.item.isPublished, 
            true
        )
          .then(()=>{
              if (mainStore.conflictType === 'save') {
                  const data = contentStore.item
                  UniversalHttp.put(instanceName, id, data).then(()=>{
                      setRestoreItem(true)
                      mainStore.isConflictForce = false
                      mainStore.stopLoading = true
                  })
              } else {
                  setRestoreItem(true)
                  mainStore.isConflictForce = false
                  mainStore.stopLoading = true
              }
          })
        mainStore.isConflictForce = false
      }, [mainStore.isConflictForce])

    useEffect(()=>{
        if (mainStore.stopLoading) {
            setIsSaveLoading(false)
            setIsPublishLoading(false)
        }

        mainStore.stopLoading = false
    }, [mainStore.stopLoading])

    useEffect(()=>{
        if (id && mainStore.isConflictPopup && mainStore.conflictType == 'save') {
            mainStore.isSaved = false
            contentStore.item.isPublished = true
        }
    }, [mainStore.conflictType])

    const onPublish = async () => {
        setIsPublishLoading(true)

        await UniversalHttp.putIsPublishedOnServer(
            instanceName, 
            id, 
            !contentStore.item.isPublished, 
        )
        setRestoreItem(true)

        mainStore.stopLoading = true
    }

    const onSave = async () => {
        setIsSaveLoading(true)

        const data = contentStore.item
        if (!id)  {
            id = await UniversalHttp.post(instanceName, data)
            if (id) router.router(`/admin/${instanceName}/${id}`);
        } else {
            if (instanceName == 'projects') {
                if (!(contentStore.item?.hasAddress)) 
                    data.address = PROJECT_INIT.address

            }
            const res = await UniversalHttp.put(instanceName, id, data)
            if (res) setRestoreItem(true)
        }

        mainStore.stopLoading = true
    }

    return (
        <ContentContainer>
            <ContentHead
                createOnly={createOnly}
                contentName={BY_INSTANCE_NAME_CONSTANTS[instanceName].title}
                backDestination={`/admin/${instanceName}`}
                onSave={onSave}
                contentId={id}
                obj={contentStore.item}
                isSaveLoading={isSaveLoading}
                isPublisheLoading={isPublishLoading}
                onPublish={onPublish}
            />
            <MainDeskComponent />
            <OptionsContainerComponent />
        </ContentContainer>
    );
}

const o = observer(UniversalContent)
export {o as UniversalContent}