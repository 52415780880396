import React from 'react';
import {Divider} from "@mui/material";
import styled from 'styled-components';
import { ToolboxProfile } from '../Profile';


const ToolboxFooterContainer = styled.div`
    display: grid;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 232px;
`

function ToolboxFooter(props) {
    return (
            <ToolboxFooterContainer>
                <Divider />
                <ToolboxProfile />
            </ToolboxFooterContainer>
    );
}

export default ToolboxFooter;