import { Typography } from "@mui/material";

export const Title = ({ label }) => {
    const titleStyle = {
        fontSize: '12px',
        fontWeight: 700,
        marginTop: '8px',
        marginLeft: '2px',
    } 
    return (
        <Typography style={titleStyle}>{label}</Typography>
    );
}