import { CardMedia, IconButton, ImageListItem, ImageListItemBar, Paper, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import React from "react"

export const MediaItem = ({
    item,
    onDelete,
    isImageNotVideo,
}) => {
    const MediaItemCore = () => isImageNotVideo ?
            <img
                src={item?.url}
                loading="lazy"
                style={{border: "none", height: 100}}
            />
        :
            <CardMedia 
                sx={{
                    border: "none",
                    height: 100,
                }}
                controls
                src={item?.url}
                component='video'
            />

    return (
        <ImageListItem>
            <Paper elevation={12} style={{height: 95}}>
                <MediaItemCore />
            </Paper>
            <ImageListItemBar
                sx={{
                    background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                }}
                title={<Typography>{item?.name}</Typography>}
                position="top"
                actionIcon={
                    <IconButton
                        sx={{ color: 'white' }}
                        onClick={()=>onDelete(item?.url)}
                    >
                        <DeleteIcon color="secondary" />
                    </IconButton>
                }
                actionPosition="right"
            />
        </ImageListItem>
    )
}