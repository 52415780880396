import React from "react";
import styled from "styled-components";

export const Label = ({ text, children }) => {
    const LabelTextStyle = styled.div`
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
        padding-bottom: 3px;
    `
    
    return  (
        <div>
            <LabelTextStyle>{text}</LabelTextStyle>
            {children}
        </div>
    );
}