import { Divider, Paper, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

const Desk = ({ children, noMargin=false }) => {
    const DeskPaperStyle = {
        width: '232px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
    }

    const DeskBody = styled.div`
        padding: 8px 16px 16px 16px;
    `

    const ChildrenCustomise = styled.div`
        padding: ${noMargin ? 0 : '8px'} 0 ${noMargin ? 0 : '8px'} 0;
    `
    
    const renderChildren = () => React.Children.map(children, child => {
        return <ChildrenCustomise>{child}</ChildrenCustomise>
    })

    return (
        <Paper sx={DeskPaperStyle}>
            <DeskBody>
                {renderChildren()}
            </DeskBody>
        </Paper>
    );
}

const observed = observer(Desk)
export {observed as Desk};

export const SeparatedTextInDesk = ({ label,  text }) => {
    const SeparatedStyle = styled.div`
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
    `

    const Label = styled.span`
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
    `

    const Text = styled.span`   
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;

    `

    return (
        <SeparatedStyle>
            <Label>{label}</Label>
            <Text>{text}</Text>
        </SeparatedStyle>
    );
}

export const TextWithDivider = ({ text }) => {
    const TextStyle = styled.div`
        font-size: 11px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #969696;
        padding: 8px 0 8px 0;
    `

    const Text = () => {
        return <TextStyle>{text.toUpperCase()}</TextStyle>
    }

    return (
        <div>
            <Text />
            <Divider />
        </div>
    );
}
