import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { UniversalContent } from "../components/UniversalContent";
import { Information } from "../components/Information";
import { MainDesk } from "../components/MainDesk";
import { InputTextField } from "../components/forms/InputTextField";
import { contentStore } from "../store/Store";
import { OptionsContainer } from "../styles/ContentStyles";
import { Label } from "../components/Label";
import { FormHelperText, Paper, Typography } from "@mui/material";
import Markdown from "markdown-to-jsx";
import { mainStore } from "../store/MainStore";
import userStore from "../store/UserStore";
import { adminAxios } from "../services/http/adminAxios";

const User = () => {
    const noteText = 'Please note that after creating a user, it becomes impossible to find out his password and change it or username. \n\n *\*The administrator has permission only to delete users and create new ones.*'

    useEffect(()=>{
        adminAxios.post('is-admin-check', {username: userStore.user.username.value})
            .then((res)=>{
                mainStore.forbiddenError = !res.data.isAdmin
            })
    },[])

    const UserMainDesk = () => (
        <MainDesk numOfLeftChildren={3}>
            <Label text={'Username'}>
                <InputTextField
                    language='ru'
                    required={true}
                    obj={contentStore.item?.title}
                    readOnly={contentStore.item?.id}
                />
            </Label>
            <Label text='Password'>
                <InputTextField
                    readOnly={contentStore.item?.id}
                    type={contentStore.item?.id ? "password" : ''}
                    required={true}
                    obj={contentStore.item?.password}
                />
            </Label>
            <Paper variant='outlined' elevation={10} style={{padding: '10px 20px 10px 20px' , fontSize: 14}}>
                <Markdown style={{whiteSpace: 'pre-line', color: 'lightgray'}}>{noteText}</Markdown>
            </Paper>
        </MainDesk>
    );

    const UserOptions = () => (
        <OptionsContainer>
            <Information 
                obj={contentStore.item}
            />
        </OptionsContainer>
    )

    return (
        <UniversalContent 
            createOnly={true}
            MainDeskComponent={UserMainDesk}
            OptionsContainerComponent={UserOptions}
        />
    )
}

const o = observer(User)
export {o as User}