import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { UniversalContent } from "../components/UniversalContent";
import { Information } from "../components/Information";
import { MainDesk } from "../components/MainDesk";
import { BiLanguageForm } from "../components/forms/BiLanguageForm";
import { InputTextField } from "../components/forms/InputTextField";
import { contentStore } from "../store/Store";
import { OptionsContainer } from "../styles/ContentStyles";
import { SimpleSelect } from "../components/forms/SimpleSelect";
import { XYZField } from "../components/forms/XYZ";
import { FileInput, fileToFileItem } from "../components/forms/FileInput";
import { BigToggle } from "../components/forms/BigToggle";
import _ from "lodash";
import { Relations } from "../components/Relations";
import { UniversalHttp } from "../services/http/UniversalHttp";
import { OneAutocomplete } from "../components/forms/OneAutocomplete";

const ArContent = () => {
    const fetchProjects = async () => await UniversalHttp.get('projects')
    const getArContentType = () => contentStore.item.type?.value

    const typeOfArContentOptions = [
        'imageTargetVideo', // image field optional
        'imageTargetModel', // image field optional
        'planeTargetModel'
    ]

    const arContentTypesTitles = {
        imageTargetVideo: 'Image Target Video', // image field optional
        imageTargetModel: 'Image Target Model', // image field optional
        planeTargetModel: 'Plane Target Model'
    }

    const setImageIfTarget = (filesToState) => { 
        // handle uploaded target file before setting it to target values (contentStore.item.target and window.FILES['target'])  
        window.FILES.details.isImageFromTarget = false

        // True if no Image in file's or mobx's values:
        const isImage = !contentStore.item.image.items.length 
        
        // Check if existing image equals to existing target (if both came from server) 
        const isImageItemEqualsToTargetItem = contentStore.item.image.items.length && contentStore.item.target.items.length 
            && _.isEqual(contentStore.item.image.items[0], contentStore.item.target.items[0])

        // Check if existing image equals to existing target (after reuploading target) 
        const isImageItemEqualsToTargetFile = 
            contentStore.item.image.items[0]?.name === window.FILES['target'][0]?.name && 
            contentStore.item.image.items[0]?.size === window.FILES['target'][0]?.size

        // Check if image file exist
        const isImageFile = window.FILES['image'].length

        if (!isImageFile && (isImage || isImageItemEqualsToTargetItem || isImageItemEqualsToTargetFile)) {
            const file = filesToState[0]
            const imageItem = fileToFileItem(file)

            contentStore.item.image.items = [imageItem]
            window.FILES.details.isImageFromTarget = true
        }
    }

    const deleteImageIfEqualToTarget = (deletedFileItem) => {
        // cant simply lodash._.isEqual(...) because they have different url's ('blob:...')
        if (
            contentStore.item.image.items.length &&
            deletedFileItem.name===contentStore.item.image.items[0].name &&
            deletedFileItem.size===contentStore.item.image.items[0].size
        ) {
            contentStore.item.image.items = []
        }
    }

    useEffect(()=>{
    }, [getArContentType()])

    const ArContentMainDesk = () => (
        <MainDesk numOfLeftChildren={6}>
                <BiLanguageForm label="Name">
                    <InputTextField
                        required={true}
                        obj={contentStore.item?.title}
                    />
                </BiLanguageForm>
                <SimpleSelect 
                    options={typeOfArContentOptions}
                    label='Type'
                    obj={contentStore.item.type}
                    optionsValuesTitles={arContentTypesTitles}
                />
                <FileInput
                    type="file"
                    label='File'
                    globalStoreSubValue={'model'}
                    obj={contentStore.item?.model}
                    multiple={false}
                />
                <XYZField 
                    label='Position'
                    obj={contentStore.item?.positionPercent}
                />
                <XYZField 
                    label='Scale'
                    obj={contentStore.item?.scalePercent}
                />
                <XYZField
                    label='Rotation'
                    obj={contentStore.item?.rotation}
                />
                <BigToggle 
                    obj={contentStore.item?.hasMask}
                    label={'hasMask'}
                    disabled={getArContentType() !== 'imageTargetVideo'}
                />
                <FileInput
                    type="file"
                    label='Config'
                    globalStoreSubValue={'config'}
                    obj={contentStore.item?.config}
                    multiple={false}
                />
                <FileInput
                    type="image"
                    label='Image'
                    globalStoreSubValue={'image'}
                    obj={contentStore.item?.image}
                    multiple={false}
                />
                <FileInput
                    type="image"
                    label='Target'
                    globalStoreSubValue={'target'}
                    obj={contentStore.item?.target}
                    multiple={false}
                    handleDownloadedFiles={setImageIfTarget}
                    handleDeletedItems={deleteImageIfEqualToTarget}
                    disabled={getArContentType() == 'planeTargetModel'}
                />
        </MainDesk>
    );

    const ArContentOptions = () => (
        <OptionsContainer>
            <Information 
                obj={contentStore.item}
            />
            <Relations>
                <OneAutocomplete 
                    fetchOptions={fetchProjects}
                    obj={contentStore.item?.project}
                    label='Projects'
                />
            </Relations>
        </OptionsContainer>
    )

    return (
        <UniversalContent 
            MainDeskComponent={ArContentMainDesk}
            OptionsContainerComponent={ArContentOptions}
        />
    )
}

const o = observer(ArContent)
export {o as ArContent}