import _ from "lodash";

export class ErrorHandler {
    storeItem;
    cleanErrorList = [];

    constructor (store, res) {
        const details = res.response.data.detail

        this.storeItem = store.item
        for (let errorIndex = 0; errorIndex < details.length; errorIndex++) {
            const errorItem = details[errorIndex]
            const lastInLocation = errorItem.loc.slice(-1)[0]
            const error = {
                locationList: ['ru', 'eng'].includes(lastInLocation) ?
                    errorItem.loc.slice(2, -1) : errorItem.loc.slice(2),
                message: errorItem.msg
            }

            this.cleanErrorList.push(error)
        }
    }

    setErrors = () => {
        for (let error_index = 0; error_index < this.cleanErrorList.length; error_index++) {
            const errorItem = this.cleanErrorList[error_index]
            const locationList = errorItem.locationList
            let errorStringAttrs = locationList[0].toString()
            
            if (locationList.length>1) for (let i = 1 ; i < locationList.length; i++) {
                const newErrorAttr = isNaN(locationList[i]) ? `.${locationList[i]}` : `.items[${locationList[i].toString()}]`
                errorStringAttrs = errorStringAttrs + newErrorAttr
            }
            
            _.set(this.storeItem, `${errorStringAttrs}.error`, errorItem.message)
        }
    }
}