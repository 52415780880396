import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { mainStore } from '../../store/MainStore';
import { SmartRouter } from '../../services/Router';
import { observer } from 'mobx-react-lite';
import { UniversalHttp } from '../../services/http/UniversalHttp';
import { BY_INSTANCE_NAME_CONSTANTS } from '../../constants/StoreConstants';
import { SimpleAlert } from './simplePopup';
import Markdown from 'markdown-to-jsx';
import { Paper, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TokenService from '../../services/http/TokenService';
import userStore from '../../store/UserStore';
import { useEffect, useState } from 'react';


function SaveAlertDialog({ 
}) {
  const router = new SmartRouter()

  const handleClose = () => {
    mainStore.isSaveDialogOpen = false;
  };

  const handleDisagree = () => {
    handleClose()
    mainStore.saveDialogResult = false
  } 

  const handleAgree = () => {
    handleClose()
    mainStore.saveDialogResult = true
  } 

  return (
    <div>
      <Dialog
        open={mainStore.isSaveDialogOpen}
        onClose={handleClose}
        aria-labelledby="save-dialog-title"
        aria-describedby="save-dialog-description"
      >
        <DialogTitle id="save-dialog-title">
          {"Exit without save?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="save-dialog-description">
            It looks like you have been editing something.<br />
            If you leave before saving, your changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgree} color='error'>
            Leave
          </Button>
          <Button onClick={handleDisagree}>
            Stay on
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const o = observer(SaveAlertDialog)
export {o as SaveAlertDialog}


const OnLogoutDialog = () => {
  const router = new SmartRouter()

  const handleClose = () => {
    mainStore.isLogoutDialogOpen = false;
  };

  const handleDisagree = () => {
    handleClose()
  } 

  const handleAgree = () => {
    handleClose()
    TokenService.clearToken()
    userStore.user = {username: {value: ''}, password: {value: ''}}
    router.to('/admin/sign-in')
  } 

  return (
    <div>
      <Dialog
        open={mainStore.isLogoutDialogOpen}
        onClose={handleClose}
        aria-labelledby="save-dialog-title"
        aria-describedby="save-dialog-description"
      >
        <DialogTitle id="save-dialog-title">
          Logout
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="save-dialog-description">
            Are you sure you want to exit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgree} color='error'>
            YES
          </Button>
          <Button onClick={handleDisagree}>
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const oooooo = observer(OnLogoutDialog)
export {oooooo as OnLogoutDialog}


const OnUserNotAuthentificatedPopup = () => {
  const router = new SmartRouter()

  const handleClose = () => {
    userStore.isUserNotAuth = false
    router.to('/admin/sign-in')
  };

  const handleAgree = () => {
    userStore.user = {username: {value: ''}, password: {value: ''}}
    handleClose()
  } 

  return (
    <div>
      <Dialog
        open={userStore.isUserNotAuth && !window.location.pathname.endsWith("/sign-in")}
        onClose={handleClose}
        aria-labelledby="save-dialog-title"
        aria-describedby="save-dialog-description"
      >
        <DialogTitle id="save-dialog-title">
          Authentication failed
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="save-dialog-description">
            You need to login
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAgree} color='error'>
            TO LOGIN PAGE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const oooooos = observer(OnUserNotAuthentificatedPopup)
export {oooooos as OnUserNotAuthentificatedPopup}


const OnNotFound = () => {
  const router = new SmartRouter()

  const handleClose = () => {
    mainStore.isNotFound = false
    router.to('/admin')
  };

  return (
    <div>
      <Dialog
        open={mainStore.isNotFound}
        onClose={handleClose}
        aria-labelledby="save-dialog-title"
        aria-describedby="save-dialog-description"
      >
        <DialogTitle id="save-dialog-title">
          Entry not found
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="save-dialog-description">
            This content does not exist
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            to start page
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const ooooooss = observer(OnNotFound)
export {ooooooss as OnNotFound}


const OnForbiddenPopup = () => {
  const router = new SmartRouter()

  const handleClose = () => {
    mainStore.forbiddenError = false
    router.to('/admin')
  };

  return (
    <div>
      <Dialog
        open={mainStore.forbiddenError}
        onClose={handleClose}
        aria-labelledby="save-dialog-title"
        aria-describedby="save-dialog-description"
      >
        <DialogTitle id="save-dialog-title">
          Forbidden
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="save-dialog-description">
            You do not have permission to view this page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            to start page
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const oooooossw = observer(OnForbiddenPopup)
export {oooooossw as OnForbiddenPopup}


function OnConflictPopup() {
  const handleClose = () => {
    mainStore.isConflictPopup = false;
    mainStore.isConflictDialog = false;
  };

  const handleAgree = () => {
    mainStore.isConflictForce = true
    handleClose()
  }

  const agreeButtonStyle = mainStore.conflictType == 'delete' ? 'error' : 'warning'

  return <Dialog
    open={mainStore.isConflictPopup}
    onClose={handleClose}
  >
    <DialogTitle id="delete-dialog-title">
      <Typography variant='h6'><u style={{fontWeight: 'bold', textDecorationStyle: 'wavy'}}>{mainStore.conflictType.charAt(0).toUpperCase() + mainStore.conflictType.slice(1)} conflict</u></Typography>
    </DialogTitle>
    <DialogContent>
      <Paper square={true} style={{marginBottom: 15}}>
        <DialogContentText id="save-dialog-description" style={{whiteSpace: 'pre-line', padding: '5px 20px 5px 20px'}}>
          <Markdown>{mainStore.conflictText || ''}</Markdown>
        </DialogContentText>
      </Paper>
      {mainStore.isConflictDialog ? <DialogContentText id="save-dialog-description" style={{display: 'grid', justifyItems:'end'}}>
        <Typography variant='h5' style={{marginTop: 10, marginBottom: -20, fontWeight: 700}}>Continue?</Typography>
      </DialogContentText> : null}
    </DialogContent>
    <DialogActions style={{marginTop: -5}}>
      {
        mainStore.isConflictDialog ? 
          <div>
            <Button onClick={handleClose} size='large'>
              Disagree
            </Button>
            <Button onClick={handleAgree} color={agreeButtonStyle} size='large' style={{margin: '0 15px 0 10px'}}>
              Agree
            </Button>
          </div>
        :
          <Button onClick={handleClose}>
            Okay
          </Button>
      }
    </DialogActions>
  </Dialog>
}

const ooo = observer(OnConflictPopup)
export {ooo as OnConflictPopup}


function OnPublishSuccessPopup() {
  const handleClose = () => {
    mainStore.isPublishSuccessPopup = false;
  };

  return <Dialog
    open={mainStore.isPublishSuccessPopup}
    onClose={handleClose}
  >
    <DialogTitle id="delete-dialog-title">
      <Typography variant='h6'><u style={{fontWeight: 'bold', textDecorationStyle: 'double'}}>Published!</u></Typography>
    </DialogTitle>
    <DialogContent>
      <Paper square={true} style={{marginBottom: 15}}>
        <DialogContentText id="save-dialog-description" style={{whiteSpace: 'pre-line', padding: '5px 20px 5px 20px'}}>
          <Markdown>{mainStore.publishSuccessText || ''}</Markdown>
        </DialogContentText>
      </Paper>
    </DialogContent>
    <DialogActions style={{marginTop: -5}}>
      <Button onClick={handleClose}>
        Okay
      </Button>
    </DialogActions>
  </Dialog>
}

const ooop = observer(OnPublishSuccessPopup)
export {ooop as OnPublishSuccessPopup}


function DeleteAlertDialog({
}) {
  const handleClose = () => {
    mainStore.isDeleteDialogOpen = false;
  };

  const handleDisagree = () => {
    handleClose()
    mainStore.deleteDialogResult = false
  } 

  const handleAgree = () => {
    handleClose()
    mainStore.deleteDialogResult = true
  } 

  return (
    <Dialog
      open={mainStore.isDeleteDialogOpen}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        {`Delete ${BY_INSTANCE_NAME_CONSTANTS[mainStore.deleteDetails?.instanceName]?.title.toLowerCase()}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Are you sure want to delete?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAgree} color='error'>
          Delete
        </Button>
        <Button onClick={handleDisagree}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const ob = observer(DeleteAlertDialog)
export {ob as DeleteAlertDialog}


export const ServerErrorSnackbar = () => {
  return (
    <SimpleAlert 
      fieldName={'isInternalServerError'}
      text={'Internal Server Error! Try again later.'}
    />
  )
}

export const ValidationError = () => {
  return (
    <SimpleAlert
      fieldName={'isValidationError'}
      text={'Validation Error! Check the entered files and fields and try again.'}
    />
  )
}

