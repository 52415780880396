import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";


class MainStore {
    theme = 'light'
    themeSwitched = false
    isSaved = null;

    isSaveDialogOpen = false;
    saveDialogResult = null;
    routeUrlFromDialog;

    conflictType = '';
    isConflictPopup = false;
    conflictText = '';
    isConflictDialog = false;
    isConflictForce = false;

    isDeleteDialogOpen = false;
    deleteDialogResult = null;
    deleteDetails;
    deleteEnded=false;

    stopLoading=false;
    isInternalServerError=false;
    isValidationError = false;

    isLogoutDialogOpen = false;
    isNotFound = false;
    forbiddenError = false;

    isPublishSuccessPopup=false;
    publishSuccessText=''

    constructor(){
        makeAutoObservable(this);   
        makePersistable(this, { name: 'MainStore', properties: ['theme', 'themeSwitched'], storage: window.localStorage });
    }   
}

export const mainStore = new MainStore();
