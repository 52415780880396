import { toJS } from "mobx";
import { contentStore } from "../../store/Store";
import { ErrorHandler } from "./Error";
import { Serializer } from "./Serializer";
import { adminAxios } from "./adminAxios";
import { mainStore } from "../../store/MainStore";

class UniversalHttp {
    serializer = new Serializer()

    async get (instanceName, id='') {
        const res = await adminAxios.get(`${instanceName}${id ? '/' + id : ''}`)
        
        const serialized = this.serializer.fromBackendToAdmin(res.data)
        return serialized 
    } 

    async post (instanceName, data) {
        if (data.hasOwnProperty('isPublished')) data.isPublished = Boolean(data.isPublished)
        const dataToServer = this.serializer.fromAdminToBackend(toJS(data))
        console.log(dataToServer)
        try {
            const res = await adminAxios.post(`${instanceName}`, dataToServer)
            var {id} = res.data
        } catch (e) {
            if (e.response.status === 422) {
                console.log(e)
                const error = new ErrorHandler(contentStore, e)
                error.setErrors()
            } else {
                console.log(e)
            }

            id = false
        } finally {
            if (id && !isNaN(id)) {
                try {
                    await this.postFiles(instanceName, id)
                } catch (e) {
                    console.log(e)
                }
            }
        }

        return id
    }

    async put (instanceName, id, data) {
        const dataToServer = this.serializer.fromAdminToBackend(toJS(data))
        var result = false;
        try {
            const res = await adminAxios.put(`${instanceName}/${id}`, dataToServer)

            result = this.serializer.fromBackendToAdmin(res.data)
        } catch (e) {
            if (e.response.status === 422) {
                const error = new ErrorHandler(contentStore, e)
                error.setErrors()
            } else {
                console.log(e)
            }
        } finally {
            if (result) {
                try {
                    await this.postFiles(instanceName, id)
                } catch (e) {
                    console.log(e)
                }
            }
        }

        return result
    }

    async delete (instanceName, ids) {
        for (let i = 0; i < ids.length; i++) {
            await adminAxios.delete(`${instanceName}/${ids[i]}${mainStore.isConflictForce ? '?force=true' : ''}`)
        }
        mainStore.isConflictForce = false
    }

    async putIsPublishedOnServer (instanceName, ids, isPublished, force=false) {
        const items = typeof ids === typeof [] ? toJS(contentStore.items) : [toJS(contentStore.item)]


        for (let i = 0; i < items.length; i++) { 
            const item = items[i]
            
            if (ids.includes(item.id)) {
                const data = {isPublished: isPublished}
                const res = await adminAxios.post(`${instanceName}/${item.id}/publish${force ? '?force=true' : ''}`, data)
                if (res.status = 200) {
                    contentStore.item.isPublished = res.data.isPublished
                }
            }
        }
    }

    async postFiles(instanceName, id) {
        const filesDict = window.FILES
        const filesFormData = new FormData()

        if (filesDict.hasOwnProperty('details')) {
            for (const [detailName, detailValue] of Object.entries(filesDict.details)) {
                filesFormData.append(detailName, detailValue)
            }
            delete filesDict.details
        }

        for (const [fieldName, listOfFileItems] of Object.entries(filesDict)) {
            listOfFileItems.forEach(
                fileItem =>
                    {
                        const file = fileItem.file
                        const fileName = fileItem.name

                        filesFormData.append(fieldName, file, fileName)
                    }
            )
        }

        var res;
        try {
            res = await adminAxios.post(`${instanceName}/${id}/files`, filesFormData)
        } catch (responseError) {
            
        }

        return res
    }
}

const uh = new UniversalHttp()
export {uh as UniversalHttp}