import { IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";
import { mainStore } from "../../store/MainStore";
import _ from "lodash";
import { observer } from "mobx-react-lite";

const SimpleAlert = ({
    fieldName,
    text
}) => {
    const handleClose = () => {
        _.set(mainStore, fieldName, false)
        return
      }
    
    const action = (
    <React.Fragment>
        <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
        >
        <CloseIcon fontSize="small" />
        </IconButton>
    </React.Fragment>
    );

    return (
    <Snackbar open={mainStore[fieldName]} autoHideDuration={5000} onClose={handleClose}>
        <Alert sx={{ width: '100%' }} severity="error" action={action}>
            {text}
        </Alert>
    </Snackbar>
    )
}

const obs = observer(SimpleAlert)
export {obs as SimpleAlert}