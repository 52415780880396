import { FormHelperText, useTheme } from '@mui/material';
import MDEditor, { commands } from '@uiw/react-md-editor';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { mainStore } from '../../store/MainStore';
import Markdown from 'markdown-to-jsx';
import he from 'he';
import styled from 'styled-components';

const ReactDOMServer = require('react-dom/server');

const MarkDown = ({language='', obj, placeholder='', height=90}) => {
    const inputRef = useRef();
    const theme = useTheme();
    const [mdEditorStyle, setMdEditorStyle] = useState()
    const [isFocus, setIsFocus] = useState(false)

    const changeMDCaretPosition = (shiftBack) => {
        let currentStart = inputRef.current.textarea.selectionStart
        let currentEnd = inputRef.current.textarea.selectionEnd
        if (currentEnd==currentStart){
            inputRef.current.textarea.selectionEnd
            = inputRef.current.textarea.selectionStart
            = currentStart - shiftBack 
        }
    }

    const getValue = () => _.get(obj, `${language}.value`, obj?.value)
    const getError = () => obj?.error
    const setValue = (value) => {
        try {
            obj[language].value = value
        } catch {
            obj.value = value
        }
    }
    const setError = (error) => {
        obj.error = error
    }

    const [mdValue, setMdValue] = useState(getValue() || '')

    const activeMarkdownStyle = {
        outline: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 4,
    }

    const errorMarkdownStyle = {
        outline: `1px solid ${theme.palette.error.main}`,
        borderRadius: 4,
    }

    useEffect(()=>{
        if (obj?.error){
            setMdEditorStyle(errorMarkdownStyle)
        } else {
            if(!isFocus) {
                setMdEditorStyle(undefined)
            }
        }
    }, [obj?.error])
    
    const h = (num) => {
        return { 
            name: `h${num}`,
            keyCommand: `h${num}`,
            buttonProps: { 'aria-label': `Insert h${num}` },
            render: (command, disabled, executeCommand) => {
                return (
                    <button 
                        onClick={(evn) => {
                            executeCommand(command, command.groupName)
                            changeMDCaretPosition(8)
                        }}
                    >
                        <div style={{fontSize: 17}}><b>{`H${num}`}</b></div>
                    </button>)},
            execute: (state, api) => {
                let modifyText = `<style="H${num}">${state.selectedText}</style>`;
                if (!state.selectedText) {
                    modifyText = `<style="H${num}"></style>`;
                }
                api.replaceSelection(modifyText);
            },
    }};

    const h1 = h(1)
    const h2 = h(2)
    const h3 = h(3)
    const h4 = h(4)
    const h5 = h(5)
    const h6 = h(6)

    const Link = {
        name: `Link`,
        keyCommand: `Link`,
        buttonProps: { 'aria-label': `Insert Link` },
        render: (command, disabled, executeCommand) => {
            return (
                <button 
                    onClick={(evn) => {
                        executeCommand(command, command.groupName)
                        changeMDCaretPosition(12)
                    }}
                >
                    <div style={{fontSize: 17}}><b><u>{`link`}</u></b></div>
                </button>)},
        execute: (state, api) => {
            let modifyText = `<style="Link"><a>${state.selectedText}</a></style>`;
            if (!state.selectedText) {
                modifyText = `<style="Link"><a></a></style>`;
            }
            api.replaceSelection(modifyText);
        },
    };

    const i = {
        name: `i`,
        keyCommand: `ctrl+i`,
        buttonProps: { 'aria-label': 'Insert <i>' },
        render: (command, disabled, executeCommand) => {
            return (
                <button 
                    onClick={(evn) => {
                        executeCommand(command, command.groupName)
                        changeMDCaretPosition(4)
                    }}
                >
                    <div style={{fontSize: 17}}><i><b>{`i`}</b></i></div>
                </button>)},
        execute: (state, api) => {
            let modifyText = `<i>${state.selectedText}</i>`;
            if (!state.selectedText) {
                modifyText = `<i></i>`;
            }
            api.replaceSelection(modifyText);
        },
    };

    const b = {
        name: `b`,
        keyCommand: `b`,
        buttonProps: { 'aria-label': `Insert <b>` },
        render: (command, disabled, executeCommand) => {
            return (
                <button 
                    onClick={(evn) => {
                        executeCommand(command, command.groupName)
                        changeMDCaretPosition(4)
                    }}
                >
                    <div style={{fontSize: 17}}><b>{`b`}</b></div>
                </button>)},
        execute: (state, api) => {
            let modifyText = `<b>${state.selectedText}</b>`;
            if (!state.selectedText) {
                modifyText = `<b></b>`;
            }
            api.replaceSelection(modifyText);
        },
    };

    const u = {
        name: `u`,
        keyCommand: `ctrl+u`,
        buttonProps: { 'aria-label': 'Insert <u>' },
        render: (command, disabled, executeCommand) => {
            return (
                <button 
                    onClick={(evn) => {
                        executeCommand(command, command.groupName)
                        
                    }}
                >
                    <div style={{fontSize: 17}}><b><u>{`u`}</u></b></div>
                </button>)},
        execute: (state, api) => {
            let modifyText = `<u>${state.selectedText}</u>`;
            if (!state.selectedText) {
                modifyText = `<u></u>`;
            }
            api.replaceSelection(modifyText);
            changeMDCaretPosition(4)
        },
    };


    const s = {
        name: `s`,
        keyCommand: `ctrl+s`,
        buttonProps: { 'aria-label': 'Insert <s>' },
        render: (command, disabled, executeCommand) => {
            return (
                <button 
                    onClick={(evn) => {
                        executeCommand(command, command.groupName)
                        changeMDCaretPosition(4)
                    }}
                >
                    <div style={{fontSize: 17}}><b><s>{`s`}</s></b></div>
                </button>)},
        execute: (state, api) => {
            let modifyText = `<s>${state.selectedText}</s>`;
            if (!state.selectedText) {
                modifyText = `<s></s>`;
            }
            console.log(modifyText)
            api.replaceSelection(modifyText);
        },
    };

    const br = {
        name: `br`,
        keyCommand: `br`,
        buttonProps: { 'aria-label': `Insert br` },
        icon: <div style={{fontSize: 17}}><b>{`<br>`}</b></div>,
        execute: (state, api) => {
            let modifyText = `${state.selectedText}<br>`;
            if (!state.selectedText) {
                modifyText = `<br>`;
            }
            api.replaceSelection(modifyText);
        },
    }

    function htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
      }

    const Style = ({children, ...props}) => {
        const U = styled.u`
            color: #0000FF !important;
        `

        if (props.Link) {
            children = <U>{children}</U>
        } 

        if (props.H1){
            console.log({...props})
            children = <h1>{children}</h1>
        } else if (props.H2) {
            children = <h2>{children}</h2>
        } else if (props.H3) {
            children = <h3>{children}</h3>
        } else if (props.H4) {
            children = <h4>{children}</h4>
        } else if (props.H4) {
            children = <h5>{children}</h5>
        } else if (props.H6) {
            children = <h6>{children}</h6>
        } 

        return children
    }

    return (
        <div data-color-mode={mainStore.theme}>
            <MDEditor
                height={height}
                id='1221'
                commands={[
                    b, i, u, s, br, Link,
                    commands.group(
                        [
                            h1, h2, h3, h4, h5, h6
                        ],
                        {
                        name: "h",
                        groupName: "h",
                        icon: <div style={{fontSize: 17}}><b>H</b></div>,
                        buttonProps: { "aria-label": "Insert h" }
                        }),]}
                extraCommands={[
                    commands.codeEdit, 
                    commands.codeLive, 
                    commands.codePreview, 
                    commands.fullscreen
                ]}
                value={getValue()}
                onChange={(e) => {
                    setValue(e)
                    setMdValue(e)
                    setError(false)
                    setMdEditorStyle(activeMarkdownStyle)
                    mainStore.isSaved = false
                }}
                style={mdEditorStyle}
                onFocus={()=>{
                    setIsFocus(true)
                    if (!getError()) {
                        setMdEditorStyle(activeMarkdownStyle)
                    }
                }}
                onBlur={()=>{
                    setIsFocus(false)
                    setMdEditorStyle(undefined)}}
                styles={mdEditorStyle}
                components={{preview: (source, state, dispath) => {
                    const md = <Markdown 
                        children={mdValue}
                        options={{
                            overrides: {
                                style: {
                                    component: Style
                                }
                            }
                        }}
                    />

                    const reactHtml = he.decode(ReactDOMServer.renderToStaticMarkup(md));

                    if (/<\/style>/.test(mdValue)&&/<style="\s*.*">/.test(mdValue)) {
                        setMdValue(reactHtml)
                        return
                    } 
                    
                    return <div dangerouslySetInnerHTML={{ __html: mdValue }} />
                }}}
                placeholder={placeholder}
                enableScroll={true}
                ref={inputRef}
            />
            <FormHelperText error={true} >{getError()||" "}</FormHelperText>
        </div>
    );
}

const observedMarkDown = observer(MarkDown)
export {observedMarkDown as MarkDown};
