import { IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import { contentStore } from "../../store/Store";
import { observer } from "mobx-react-lite";

const _ = require("lodash"); 


const BiLanguageForm = ({
    label='Title',
    children,
    withRemove=false,
    language='ru',
    setRemove=()=>{},
}) => {
    const [localLanguage, setLanguage] = useState(language)
    const WIDTH = '100%'

    useEffect(()=>{
        if (contentStore.globalLanguage) setLanguage(contentStore.globalLanguage)
    }, [contentStore.globalLanguage])

    const LanguageTogler = () => {
        const languageStyle = {
            fontSize: '10px',
            fontWeight: '700',
        }

        return (
            <div>
                <ToggleButtonGroup
                    value={localLanguage}
                    exclusive
                    onChange={(e, newLanguage)=>{
                        setLanguage(newLanguage)
                        contentStore.globalLanguage = null
                    }}
                    size='small'
                    aria-label="text alignment"
                >
                    <ToggleButton 
                        value="ru"
                        sx={{width:31, height: 25}}
                        disabled={localLanguage==='ru'}
                        color="primary"
                    >
                        <Typography style={languageStyle}>Ru</Typography>
                    </ToggleButton>
                    <ToggleButton 
                        value="eng" 
                        sx={{width:31, height: 25}}
                        disabled={localLanguage==='eng'}
                        color="primary"
                    >
                        <Typography style={languageStyle}>Eng</Typography>
                    </ToggleButton>
                </ToggleButtonGroup>
                {
                    withRemove &&
                        <IconButton onClick={()=>setRemove(true)} style={{height: 30, width: 30}} color='error'>
                            <CloseIcon style={{height: 20}}/>
                        </IconButton>
                }
            </div>
        );
    }

    const Title = () => {
        const titleStyle = {
            fontSize: '12px',
            fontWeight: 700,
            marginTop: '8px',
            marginLeft: '2px',
        } 
        return (
            <Typography style={titleStyle}>{label}</Typography>
        );
    }

    const FormHead = () => {
        const FormHeadStyled = styled.div`
            display: grid;
            grid-auto-flow: column;
            justify-content: space-between;
            height: 25px;
            width: ${WIDTH};
            margin-bottom: 5px;
        `

        return (
            <FormHeadStyled>
                <Title />
                <LanguageTogler />
            </FormHeadStyled>
        );
    }

    const FormChildrenBlockStyle = styled.div`
            width: ${WIDTH};
        `

    const renderChildren = () => React.Children.map(children, child => {
          return React.cloneElement(child, {
            language: localLanguage
          })
        })

    return (
        <div>
            <FormHead />
            <FormChildrenBlockStyle>
                {renderChildren()}
            </FormChildrenBlockStyle>
        </div>
    );
}

const o = observer(BiLanguageForm)
export {o as BiLanguageForm}