export const INFORMATION_TEMPLATE = {
    createdAt: '-/-',
    createdBy: '-/-',
    updatedAt: '-/-',
    updatedBy: '-/-',
}

export const FILES_TEMPLATE = {
    items: []
}

export const BILANGUAGE_TEMPLATE = {
    ru: { 
        value: ''
    }, 
    eng: {
        value: ''
    }, 
    error: false
}

export const ONE_LANGUAGE_TEMPLATE = {
    value: '', 
    error: false,
}

export const NUMBER_TEMPLATE = {
    value: 1,
    error: false,
}

export const ZERO_NUMBER_TEMPLATE = {
    value: 0,
    error: false,
}

export const XYZ_TEMPLATE = {
    x: NUMBER_TEMPLATE,
    y: NUMBER_TEMPLATE,
    z: NUMBER_TEMPLATE,
}

export const ZERO_XYZ_TEMPLATE = {
    x: ZERO_NUMBER_TEMPLATE,
    y: ZERO_NUMBER_TEMPLATE,
    z: ZERO_NUMBER_TEMPLATE,
}

export const LINK_TEMPLATE = {
    type: ONE_LANGUAGE_TEMPLATE,
    url: ONE_LANGUAGE_TEMPLATE,
    displayText: BILANGUAGE_TEMPLATE,
}

export const ITEMS_TEMPLATE = {items: []}

export const ITEM_TEMPLATE = {item: null, error: false}

export const TAG_INIT = {
    title: {
        ...BILANGUAGE_TEMPLATE
    },

    isPublished: null,
    ...INFORMATION_TEMPLATE,
}

export const CATEGORY_INIT = {
    title: {
        ...BILANGUAGE_TEMPLATE
    },

    isPublished: null,
    ...INFORMATION_TEMPLATE,
}

export const CITY_INIT = {
    title: {
        ...BILANGUAGE_TEMPLATE
    },

    isPublished: null,
    ...INFORMATION_TEMPLATE,
}

export const PROJECT_OWNER_INIT = {
    title: BILANGUAGE_TEMPLATE,
    description: BILANGUAGE_TEMPLATE,
    links: ITEMS_TEMPLATE,
    displayImage: FILES_TEMPLATE,
    iconImage: FILES_TEMPLATE,
    isPublished: null,
    projects: ITEMS_TEMPLATE,
    ...INFORMATION_TEMPLATE,
}

export const PROJECT_INIT = {
    title: BILANGUAGE_TEMPLATE,
    description: BILANGUAGE_TEMPLATE,
    links: ITEMS_TEMPLATE,
    images: FILES_TEMPLATE,
    videos: FILES_TEMPLATE,
    address: {
        city: ITEM_TEMPLATE,
        lat: ONE_LANGUAGE_TEMPLATE,
        non: ONE_LANGUAGE_TEMPLATE,
        displayText: BILANGUAGE_TEMPLATE,
    },
    projectOwners: ITEMS_TEMPLATE,
    arcontents: ITEMS_TEMPLATE,
    tags: ITEMS_TEMPLATE,
    categories: ITEMS_TEMPLATE, 
    isPublished: null,
    ...INFORMATION_TEMPLATE,
    hasAddress: false
}

export const ARCONTENT_INIT = {
    title: BILANGUAGE_TEMPLATE,
    type: {value: 'planeTargetModel', error: false},
    image: FILES_TEMPLATE,
    target: FILES_TEMPLATE,
    model: FILES_TEMPLATE,
    config: FILES_TEMPLATE,
    positionPercent: ZERO_XYZ_TEMPLATE,
    scalePercent: XYZ_TEMPLATE,
    rotation: XYZ_TEMPLATE,
    hasMask: {value: false, error: false},
    isPublished: null,
    project: ITEM_TEMPLATE,
    ...INFORMATION_TEMPLATE,
}

export const USER_INIT = {
    title: BILANGUAGE_TEMPLATE,
    password: ONE_LANGUAGE_TEMPLATE,
    ...INFORMATION_TEMPLATE,
}

export const BY_INSTANCE_NAME_CONSTANTS = {
    'tags': {
        title: 'Tags',
        initItem: TAG_INIT,
    },
    'categories': {
        title: 'Categories',
        initItem: CATEGORY_INIT,
    },
    'ar-content': {
        title: 'AR Content',
        initItem: ARCONTENT_INIT
    },
    'projects-owners': {
        title: 'Projects Owners',
        initItem: PROJECT_OWNER_INIT,
    },
    'projects': {
        title: 'Projects',
        initItem: PROJECT_INIT,
    },
    'cities': {
        title: 'Cities',
        initItem: CITY_INIT,
    },
    'users': {
        title: 'Users',
        initItem: USER_INIT
    }
}