import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { CircularProgress, FormHelperText, Paper } from '@mui/material';
import { useState } from 'react';
import { adminAxios } from '../services/http/adminAxios';
import userStore from '../store/UserStore';
import { observer } from 'mobx-react-lite';
import { SmartRouter } from '../services/Router';

function SignIn() {
    const [isSignInLoading, setIsSignInLoading] = useState(false)
    const router = new SmartRouter()

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSignInLoading(true)
        const tokenPairUrl = `${adminAxios.defaults.baseURL?.slice(0, -5)}token/pair`
        adminAxios.post(tokenPairUrl, {
            username: userStore.user.username.value,
            password: userStore.user.password.value
        })
            .then((res)=>{
                const { access, refresh, username } = res.data
                userStore.user.password.value = ''
                userStore.user.username.value = username
                userStore.tokens.access = access
                userStore.tokens.refresh = refresh

                router.to('/admin')
            })
            .catch((err)=>{
                userStore.authError = true
            })
            .finally(()=>{
                setIsSignInLoading(false)
            })
    };

  const errorText = 'Wrong user name or password. Try with another one'
  
  return (
      <Container component="main" maxWidth="xs">
        <Paper elevation={12} style={{padding: 20, marginTop: 100, borderRadius: '10px'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Welcome back!
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              value={userStore?.user.username.value}
              onChange={(e)=>{
                userStore.user.username.value = e.target.value
                userStore.authError = false
                return
              }}
              margin="normal"
              required
              fullWidth
              label="User name"
              autoFocus
              autoComplete='new-password'
            />
            <TextField
              value={userStore?.user.password.value}
              onChange={(e)=>{
                userStore.user.password.value = e.target.value
                userStore.authError = false
                return
              }}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete='new-password'
            />
            <Box sx={{ m: 1, margin: 0, position: 'relative', justifySelf: 'start'}}>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3,}}
                    disabled={isSignInLoading || userStore.authError}
                >
                Sign In
                </Button>
                {isSignInLoading && (
                    <CircularProgress
                        size={24}
                        color='inherit'
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginLeft: '-10px',
                        }}
                    />
                )}
            </Box>
            <FormHelperText error={true} style={{marginLeft: 70}}>{userStore.authError ? errorText : ' '}</FormHelperText>
            <Typography variant="body2" color="text.secondary" align="center">ARKA Admin v2.0</Typography>
          </Box>
        </Box>
        </Paper>
      </Container>
  );
}

const o = observer(SignIn)
export {o as SignIn}