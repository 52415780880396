import { IconButton, Avatar, Menu, MenuItem, Divider, ListItem, Typography } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import userStore from "../store/UserStore";
import ThemeChangeButton from "./ThemeChangeButton";
import LogoutIcon from '@mui/icons-material/Logout';
import { adminAxios } from "../services/http/adminAxios";
import { SmartRouter } from "../services/Router";
import { mainStore } from "../store/MainStore";

const AvatarContainer = styled.div`
  width: min-content;
  height: min-content;
`
const ProfileContainer = styled.div`
  display: grid;
  align-content: center;
  justify-items: center;
  grid-template-columns: 70px 1fr;
`
const ProfileText = styled.div`
  align-self: center;
  width: 130px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function ToolboxProfile() {
    const firstLoginLetter = userStore.user.username.value.charAt(0).toUpperCase()
    const router = new SmartRouter()

    const [isOpen, setIsOpen] = useState(false)
    const [userIsAdmin, setUserIsAdmin] = useState(false)

    const onAdminClick = () => {
        router.to('/admin/users')
    }

    const onLogoutClick = () => {
        mainStore.isLogoutDialogOpen = true
    }

    useEffect(()=>{
        if (!isOpen) {
            setUserIsAdmin(false)
        } else {
            adminAxios.post('is-admin-check', {username: userStore.user.username.value})
                .then((res)=>{
                    setUserIsAdmin(res.data.isAdmin)
                })
        }
    }, [isOpen])

    return (
        <ProfileContainer>
            <PopupState variant='popover' popupId="demo-popup-menu">
                {(popupState) => {
                    setIsOpen(popupState.isOpen)
                    return <React.Fragment>
                        <AvatarContainer>
                            <IconButton {...bindTrigger(popupState)}>
                                <Avatar>{firstLoginLetter}</Avatar>
                            </IconButton>
                        </AvatarContainer>
                        <ProfileText>{userStore.user.username.value}</ProfileText>
                        <Menu {...bindMenu(popupState)}>
                            <ListItem><i>{userStore.user.username.value}</i></ListItem>
                            <Divider />
                            {
                                userIsAdmin && <MenuItem 
                                    onClick={
                                        (e)=>{
                                            popupState.close();
                                            onAdminClick();
                                            return
                                        }
                                    }
                                >
                                    Admin
                            </MenuItem>
                            }
                            <MenuItem onClick={()=>{
                                    popupState.close();
                                    onLogoutClick();
                                    return
                                }}
                            >
                                <Typography color='error'>Logout</Typography>
                                <LogoutIcon style={{marginLeft: 70}} color='error'/>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={popupState.close}><ThemeChangeButton /></MenuItem>
                        </Menu>
                    </React.Fragment>
                }}
            </PopupState>
        </ProfileContainer>
    );
}

const ToolboxProfileObserver = observer(ToolboxProfile)
export {ToolboxProfileObserver as ToolboxProfile}