import { Paper } from "@mui/material";
import React from "react";
import styled from "styled-components";

export const MainDesk = ({ children, numOfLeftChildren, keepTwoColumnsOnSecondItem=false }) => {
    if (!children) children = [];
    if (!Array.isArray(children)) children = [children];
    if (numOfLeftChildren > children.length) numOfLeftChildren = children.length;

    const MainDeskStyled = styled(Paper)`
        grid-area: desk;
        width: 731px;
        padding: 16px 0 16px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
    `

    const MainDeskStyledForOthers = styled(Paper)`
        grid-area: desk;
        width: 731px;
        padding: 0 0 16px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
    `

    const MainDeskStyledForSecondElement = styled(Paper)`
        grid-area: desk;
        width: 731px;
    `

    const ChildStyled = styled.div`
        padding: 0 16px 0 16px;
    `

    const renderChildren = (child) => <ChildStyled>{child}</ChildStyled>

    return (
        <div>
            {!keepTwoColumnsOnSecondItem ? 
                <MainDeskStyled>
                    <div>{React.Children.map(children.slice(0, numOfLeftChildren), child=>renderChildren(child))}</div>
                    <div>{React.Children.map(children.slice(numOfLeftChildren, children.length), child=>renderChildren(child))}</div>
                </MainDeskStyled>
                :
                <div>
                    <MainDeskStyled>{renderChildren(children[0])}</MainDeskStyled>
                    <MainDeskStyledForSecondElement>{renderChildren(children[1])}</MainDeskStyledForSecondElement>
                    <MainDeskStyledForOthers>
                        <div>
                            {React.Children.map(children.slice(2, 2+numOfLeftChildren), child => renderChildren(child))}
                        </div>
                        <div>
                            {React.Children.map(children.slice(2+numOfLeftChildren), child => renderChildren(child))}
                        </div>
                    </MainDeskStyledForOthers>
                </div>
            }
        </div>
    );
}