import { Autocomplete, IconButton, TextField, Typography, createFilterOptions } from "@mui/material"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import SearchIcon from '@mui/icons-material/Search';
import { observer } from "mobx-react-lite";
import { adminAxios } from "../services/http/adminAxios";

const Search = ({label='Search from all...', isSearch, setIsSearch, onChoose}) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(null)

    const loading = open && options.length === 0;
    
    useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }

        if (active) {           
            adminAxios.get('all').then(
                (res)=>{
                    console.log(res.data)
                    setOptions(res.data);
            })
        }
    
        return () => {
          active = false;
        };
      }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(()=>{
        if (value) {
            options.forEach((option)=>{
                if (option.title.ru===value || option.title.ru===value) {
                    onChoose(option)
                }
            })
        }
    }, [value])
    
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option?.title.ru  + option?.title.eng,
    });
    
    label = label!=='Search from all...'?`Search in ${label.toLowerCase()}...`:label
    
    const SearchButtonContainer = styled.div`
        display: grid;
        justify-content: center;
        align-content: center;
    `

    const SearchButton = () => <SearchButtonContainer>
        <IconButton onClick={
            ()=>setIsSearch(true)
            }>
            <SearchIcon /> 
        </IconButton>
    </SearchButtonContainer>


    const RenderedOption = ({ option }) => {
        return (
            <span style={{display: 'grid', gridTemplateColumns: 'auto auto'}} onClick={()=>onChoose(option)}>
                {option?.title?.ru} 
                <Typography color='gray'>
                    : {option?.instanceName} ({option?.id})
                </Typography>
            </span>
        )
    }


    const ToolboxSearchContainer = styled.div`
        display: grid;
        height: 64px;
        align-content: center;
    `

    const SearchField = () => 
        <ToolboxSearchContainer>
            <Autocomplete
                noOptionsText='No entries'
                openOnFocus={true}
                value={value}
                onChange={(event, newValue) => {
                    options.forEach((option)=>{
                        if (
                            option.title.ru===newValue?.title.ru || 
                            option.title.eng===newValue?.title.eng
                            ){
                            onChoose(newValue)
                        }
                    })
                }}
                onClose={() => {
                    setOpen(false);
                    setIsSearch(false)
                }}
                onOpen={() => {
                    setOpen(true);
                }}
                options={options}
                filterOptions={filterOptions}
                getOptionLabel={(option) => <RenderedOption option={option} />}
                id="free-solo-demo"
                freeSolo
                renderInput={(params) => <TextField autoFocus={true} {...params} label={label} />}
            />
        </ToolboxSearchContainer>

    return (
        <div>
            {!isSearch&&(<SearchButton />)}
            {isSearch&&(<SearchField />)}
        </div>
    );
}

const search = observer(Search)
export {search as Search};