import React from 'react';
import {Outlet} from "react-router-dom";
import styled from 'styled-components';
import Toolbox from '../components/toolbox/Toolbox';


export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 232px 1fr;
  height: 100vh;
`

function MainPage() {
    return (
        <MainContainer>
            <Toolbox />
            <Outlet />
        </MainContainer>
    );
}

export default MainPage;