import React from "react"
import { Desk, TextWithDivider } from "./Desk"

export const Relations = ({children}) => {
    return (<Desk noMargin={true}>
        <TextWithDivider text={'Options'}/>
        <div style={{marginBottom: 15}} />
        {children}
        <div style={{marginBottom: 5}} />
    </Desk>)
}