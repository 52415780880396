import React from "react";
import { InputTextField } from "./InputTextField";
import { observer } from "mobx-react-lite";

const DoubleTextInput = ({
        leftLabel, 
        leftObj, 
        isLeftNumeric=false,
        isLeftRequired=false,
        rightLabel, 
        rightObj,
        isRightNumeric=false,
        isRightRequired=false,
    }) => {
    return (
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10}}>
            <InputTextField 
                obj={leftObj}
                label={leftLabel}
                isNumeric={isLeftNumeric}
                required={isLeftRequired}
            />
            <InputTextField 
                isNumeric={isRightNumeric}
                obj={rightObj}
                label={rightLabel}
                required={isRightRequired}
            />
        </div>
    );
}

const observedInputTextField = observer(DoubleTextInput)
export {observedInputTextField as DoubleTextInput};