import { Card, CardContent, Typography, ButtonGroup, IconButton } from "@mui/material";
import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { humanReadBytes } from "./forms/FileInput";

export const FileItem = ({
    item,
    onDelete,
    onDownload,
}) => {
    const formatedSize = humanReadBytes(item?.size)
    
    return (
        <Card elevation={6}>
            <CardContent>
                <Typography noWrap style={{maxWidth: 140 }}>
                    {item?.name}
                </Typography>
                <Typography noWrap sx={{ fontSize: 14 }} color="text.secondary">
                    {formatedSize}
                </Typography>
                <ButtonGroup style={{
                    width: "100%",
                    justifyContent: "space-between",
                }}>
                    <IconButton 
                        size="small" 
                        color="primary"
                        onClick={()=>onDownload(item?.url)}
                    >
                        <DownloadIcon />
                    </IconButton>
                    <IconButton 
                        size="small" 
                        color="secondary"
                        onClick={()=>onDelete(item?.url)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </ButtonGroup>
            </CardContent>
        </Card>
    );
}