import { Autocomplete, FormHelperText, TextField, createFilterOptions } from "@mui/material"
import _ from "lodash";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { mainStore } from "../../store/MainStore";
import { Title } from "../Title";
import { contentStore } from "../../store/Store";

const ManyAutocomplete = ({
        fetchOptions,
        obj,
        placeholder="",
        placeholderOnInput='',
        label='',
        isHelperText=false,
        multiple=true,
    }) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([])
    const [isFetchingOptions, setIsFetchingOptions] = useState(false);

    const getError = () => obj?.error
    const setError = (error) => {
        obj.error = error
    }

    const loading = open && options.length === 0;
    useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }

        (async () => {
          if (active) {
            const fetchedOptions = await fetchOptions()
            
            let optionsWithoutItems = fetchedOptions.filter(option=>{
              let isIn = false
              toJS(obj?.items).map((item)=>{
                const itemId = item.id?.value || item.id 
                if (itemId === option.id) isIn = true;
              })
              return !isIn
            })

            if (obj.items.length==0 && optionsWithoutItems.length==0) {
              optionsWithoutItems = fetchedOptions;
            };
            
            setOptions(optionsWithoutItems);
          }
        })();

        setIsFetchingOptions(false)
    
        return () => {
          active = false;
        };
      }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);
    
    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => option?.title.ru?.value  + option?.title.eng?.value,
    });


    return (
      <div>
        <Title label={label} />
        <Autocomplete
            value={obj?.items}
            onChange={(event, newValue) => {
              mainStore.isSaved = false
              obj.items = newValue
              setError(false)
            }}
            onOpen={() => {
              setIsFetchingOptions(true)
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            multiple={multiple}
            loading={isFetchingOptions}
            size='small'
            noOptionsText='No entries'
            limitTags={2}
            id="multiple-limit-tags"
            options={options}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option?.title?.ru?.value}
            renderInput={(params) => (
                <TextField {...params} 
                  placeholder={placeholder}
                  error={getError()}
                />
            )}
        />
        {isHelperText && <FormHelperText error={true} >{getError()||" "}</FormHelperText>}
      </div>
    )
}

const observedManyAutocomplete = observer(ManyAutocomplete)
export {observedManyAutocomplete as ManyAutocomplete};
