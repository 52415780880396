import { TextField, Typography, FormHelperText } from "@mui/material"
import _ from "lodash"
import { observer } from "mobx-react-lite"
import React from "react"
import { mainStore } from "../../store/MainStore"

const XYZField = ({obj, label=''}) => {
    const TEXT_STYLE = {
        fontSize: '12px',
        fontWeight: 700,
    }

    const getValue = (v) => obj[v]?.value
    const getError = (v) => obj[v]?.error
    const setValue = (v, value) => {
        obj[v].value = value
    }

    const setError = (v, error) => {
        obj[v].error = error
    }

    const getFullError = () => {
        if (!obj) return ' ';

        const xError = getError('x')
        const yError = getError('y')
        const zError = getError('z')

        const anyError = xError || yError || zError
        
        if (anyError) {
            const errorText = [xError || '', yError || '', zError].join(" ")
            const n = 40
            return (errorText.length > n) ? errorText.slice(0, n-1) + '...' : errorText
        }
        return false
    }

    const labelStyle = {
        fontSize: '12px',
        fontWeight: 700,
    }
    const Label = ({label}) => <Typography style={labelStyle} children={label}/>

    let LittleInput = ({variant}) => { 
        if (!obj) return;

        const littleLabelStyle = {
            ...labelStyle,
            width: 20,
            display: 'grid',
            justifyContent: 'center',
        }

        const LittleLabel = () => <Typography 
            style={littleLabelStyle} 
            children={variant.toUpperCase()}
        />

        return (
            <div style={{
                display: 'grid', 
                gridTemplateColumns: "20px 1fr", 
                alignItems: 'center'
            }}>
                <LittleLabel />
                <TextField 
                    size='small'
                    onChange={(e)=>{
                        if (!e.target.value.match(/^-?(?!:(0{2,})|(^[0]{1,}[0-9]{1,})|([-]*[0]{1,}[0-9]{1,}))(?:0?|[0-9]*)\.?[0-9]*$/)) return; 
                        setValue(variant, e.target.value)
                        setError(variant, false)
                        mainStore.isSaved = false
                    }}
                    value={obj[variant]?.value}
                    error={getError(variant)}
                >
                    <Typography style={TEXT_STYLE}>
                        {getValue(variant)}
                    </Typography>
                </TextField>
            </div>
        )
    }

    LittleInput = observer(LittleInput)

    return (
        <div style={{width: 250}}>
            <Label label={label} />
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr'}}>
                <LittleInput variant={'x'} />
                <LittleInput variant={'y'} />
                <LittleInput variant={'z'} />
            </div>
            <FormHelperText error={true} >{getFullError()||" "}</FormHelperText>
        </div>
    );
}

const observedXYZField = observer(XYZField)
export {observedXYZField as XYZField};