import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { SmartRouter } from '../services/Router';
import styled from 'styled-components';
import { mainStore } from '../store/MainStore';
import { contentStore } from '../store/Store';
import { GlobalLanguageToggler } from './GlobalBilanguageToggler';

const ContentHead = ({
    createOnly=false,
    contentName='',
    contentId='',
    backDestination='',
    isSaveLoading=false,
    isPublisheLoading=false,
    onSave=()=>{},
    onPublish=()=>{},
    obj,
}) => {
    const router = new SmartRouter()

    const TitleText = () => {
        const text = contentId ? 'Edit an Entry' : 'Create an Entry'
        const textStyle = {
            fontSize: 32,
            fontWeight: 700,
        }
        return <Typography style={textStyle}>{text}</Typography>
    }

    const EntryInformation = () => {
        const informationStyle = {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '18px',
        }

        const ContentText = () => 
            <Typography color='primary.disabled' style={informationStyle}> 
                {`Content name: ${contentName || ''}`} 
            </Typography>

        const IdText = () => 
            <Typography color='primary.disabled' style={informationStyle}> 
                {`ID: ${contentId || ''}`}
            </Typography>

        return (
            <div>
                <ContentText />
                <IdText />
            </div>
        );
    }

    const BackButton = () => {
        const onClick = () => {
            router.to(backDestination)
        }
        return <Button 
            startIcon={<ArrowBackIcon />} 
            size='small'
            onClick={onClick}
        >
            back
        </Button>
    }

    const SaveButtonNotObserved = () => {
        const SaveIcon = [true, null].includes(mainStore.isSaved) && contentId ? 
            <DoneAllIcon /> : <DoneIcon /> 

        return <Box sx={{ m: 1, margin: 0, position: 'relative', justifySelf: 'end'}}>
                <Button 
                style={{
                    height: 'max-content',
                    width: 'max-content',
                }}
                variant='contained'
                startIcon={SaveIcon}
                onClick={onSave}
                disabled={mainStore.isSaved === null || isSaveLoading || createOnly && contentStore.item.id}
                disableRipple={mainStore.isSaved}
            >
                Save
            </Button>
            {isSaveLoading && (
                <CircularProgress
                    size={24}
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-18px',
                    }}
                />
            )}
        </Box>
    }

    const SaveButton = observer(SaveButtonNotObserved)

    const PublishButtonNotObserved = () => {
        const content = obj.isPublished ? {
            text: 'published', 
            color: 'primary',
        } : {
            text: 'in draft',
            color: 'warning',
        }

        return <Box sx={{ m: 1, margin: 0, position: 'relative', justifySelf: 'start'}}>
            <Button 
                style={{
                    height: 'max-content',
                    width: 'max-content',
                }}
                variant='outlined' 
                color={content.color}
                disabled={
                    obj?.isPublished === null || 
                    isPublisheLoading || 
                    !mainStore?.isSaved && mainStore?.isSaved!==null ||
                    createOnly 
                }
                onClick={onPublish}
            >
                {content.text}
            </Button>
            {isPublisheLoading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: obj.isPublished ? '-14px' : '-18px',
                    }}
                />
            )}
        </Box>
    }

    const PublishButton = observer(PublishButtonNotObserved)

    const HeadContainer = styled.div`
        display: grid;
        grid-area: head;
        grid-template-columns: 1fr 1fr 232px;
        margin: 0 0 32px 0;
    `

    const ButtonsContainer = styled.div`
        display: grid;
        justify-content: space-between;
        grid-auto-flow: row;
        grid-template-columns: 1fr 1fr;
        align-items: end;
    `

    return (
        <HeadContainer>
            <div>
                <BackButton />
                <TitleText />
                <EntryInformation />
            </div>
            {contentName !== 'Users' ? <GlobalLanguageToggler /> : <div></div>}
            <ButtonsContainer>
                <PublishButton/> 
                <SaveButton/>
            </ButtonsContainer>
        </HeadContainer>
    );
}

const observed = observer(ContentHead)
export {observed as ContentHead};