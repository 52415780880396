import styled from "styled-components";

export const ContentContainer = styled.div`
    margin: 31px 0 100px 117px;
    width: 971px;
    display: grid;
    grid-template-rows: 147px 1fr;
    grid-template-columns: 731px 232px;
    grid-gap: 8px;
    grid-template-areas:
        "head head"
        "desk options";
`

export const OptionsContainer = styled.div`
    display: grid;
    grid-gap: 8px;
    height: max-content;
    grid-area: options;
`