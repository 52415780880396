import React, { useEffect, useState } from "react";
import { BiLanguageForm } from "./BiLanguageForm";
import { SimpleSelect } from "./SimpleSelect";
import { InputTextField } from "./InputTextField";

export const  EntryLink = ({
    obj,
    label,
    withRemove=false,
    onRemove=()=>{},
    index=-1,
}) => {
    const [remove, setRemove] = useState(false)

    const linkTypeObj=obj?.type
    const linkUrlObj=obj?.url
    const linkDisplayTextObj=obj?.displayText

    useEffect(()=>{
        if (remove) onRemove(index)
        setRemove(false)
    }, [remove])

    const linkTypes = [
        'WEB',
        'INSTAGRAM',
        'INTERNAL',
        'YOUTUBE', 
        'VK',
        'FACEBOOK',
    ]

    return (
        <BiLanguageForm label={label} withRemove={withRemove} setRemove={setRemove}>
            <div style={{marginTop: 10}}></div>
            <SimpleSelect 
                options={linkTypes} 
                obj={linkTypeObj}
                blockLanguage={true}
                label="Type"
            />
            <InputTextField 
                obj={linkUrlObj}
                label='URL'
                blockLanguage={true}
            />
            <InputTextField 
                obj={linkDisplayTextObj}
                label='DisplayText'
            />
        </BiLanguageForm>
    );
}