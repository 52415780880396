import {makeAutoObservable} from "mobx";
import {makePersistable} from "mobx-persist-store";

class UserStore {
    user = {
        username: {
            value: ''
        }, 
        password: {
            value: ''
        }
    }

    tokens = {
        access: '',
        refresh: '',
    }

    authError = false;
    isUserNotAuth = false;


    constructor(){
        makeAutoObservable(this);
        makePersistable(this,
            {
                name: 'UserStore',
                properties: [
                    'user', 'tokens', 'isUserNotAuth'
                ],
                storage: window.localStorage
             })
    }
}

const userStore = new UserStore();
export default userStore;