import { observer } from "mobx-react-lite"
import React from "react";
import { EntryLink } from "./forms/Link";
import { FullWidthButton } from "./FullWidthButton";
import AddIcon from '@mui/icons-material/Add';
import { LINK_TEMPLATE } from "../constants/StoreConstants";
import { toJS } from "mobx";
import _ from "lodash";
import { mainStore } from "../store/MainStore";

const ListOfLinks = ({ obj, styles=null }) => {
    const onClickAdd = () => {
        obj.items.push(LINK_TEMPLATE)
        return
    }

    const onRemove = (index) => {
        mainStore.isSaved = false
        let items = toJS(obj.items)
        items.splice(index, 1)
        obj.items = items
    }

    return (
        <div style={styles}>
            {
                obj?.items?.map( (linkObj, index) =>
                    <EntryLink
                        label={'Link'}
                        obj={linkObj}
                        withRemove={true}
                        onRemove={onRemove}
                        index={index}
                    />
                )
            }
            <FullWidthButton 
                startIcon={<AddIcon style={{height: 25}}/>}
                children={'Add link'}
                onClick={onClickAdd}
            />
        </div>
    );
}

const o = observer(ListOfLinks)
export {o as ListOfLinks}