import { Paper } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Desk, SeparatedTextInDesk, TextWithDivider } from "./Desk";

export const formatDate = (dateString) => {
    if (!dateString || dateString === '-/-') {
        return "-/-"
    }
    
    const options = { year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
}

const Information = ({
    obj
}) => {
    const InformationBody = styled.div`
        padding: 20px 10px 10px 10px;
    `
    
    return (
        <Desk>
            <TextWithDivider text='information' />
            <SeparatedTextInDesk label='Creation date' text={formatDate(obj?.createdAt)} />
            <SeparatedTextInDesk label='By' text={obj?.createdBy || "-/-"}/>
            <TextWithDivider text='edited' />
            <SeparatedTextInDesk label='Last update' text={formatDate(obj?.updatedAt)} />
            <SeparatedTextInDesk label='By' text={obj?.updatedBy || "-/-"} />
        </Desk>
    );
}

const observedInformation = observer(Information)
export {observedInformation as Information};